import React, { useState, useEffect } from 'react'
import { InformationCircleIcon } from '@heroicons/react/outline'
import ReactDOMServer from 'react-dom/server'
import cx from 'classnames'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import usePersonalities from '../../hooks/usePersonalities'
import useTranslation from '../../hooks/useTranslation'

export default function PersonalityPicker({
  initialPersonality,
  onPersonalitySelected,
}) {
  const [selectedPersonality, setSelectedPersonality] =
    useState(initialPersonality)
  const { personalities } = usePersonalities()
  const t = useTranslation()

  const selectPersonality = (personality) => {
    setSelectedPersonality(personality)
    onPersonalitySelected(personality)
    ReactTooltip.hide()
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const container = (personality) => {
    return (
      <div
        className="flex flex-col items-center w-1/2 mx-auto"
        onClick={() => selectPersonality(personality)}>
        <div
          className={cx(
            'flex flex-col items-center justify-center border-2 px-8 py-4 border-transparent rounded-lg',
            {
              'bg-indigo-50 border-2 border-indigo-800 rounded-lg':
                personality.id === selectedPersonality?.id,
            },
          )}>
          <p className="px-2 text-3xl">{personality.icon}</p>
          <div className="flex items-center mt-2">
            <p
              className={cx('text-gray-400', {
                'text-indigo-800': personality.id === selectedPersonality?.id,
              })}>
              {personality.name}
            </p>
            <InformationCircleIcon
              className={cx('w-5 h-5 ml-1 text-gray-300', {
                'text-indigo-300': personality.id === selectedPersonality?.id,
              })}
              data-html={true}
              data-tip={ReactDOMServer.renderToString(
                personality.keyIndicators.map((i) => <li key={i}>{i}</li>),
              )}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="relative flex flex-col w-full pr-8">
      <div className="flex">
        <div className="flex flex-col justify-between -ml-6">
          <div className="-rotate-90 mt-14">
            <p className="text-gray-600">{t('personality.extroverted')}</p>
          </div>
          <div className="-rotate-90 mb-14">
            <p className="text-gray-600">{t('personality.introverted')}</p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full -ml-6 border-b-2 border-l-2 border-gray-400 h-72">
          <div className="flex items-center w-full border-b-2 h-1/2 border-b-gray-100">
            {container(personalities[0])}
            <div className="h-full bg-gray-100 w-0.5" />
            {container(personalities[1])}
          </div>
          <div className="flex items-center justify-center w-full h-1/2">
            {container(personalities[2])}
            <div className="w-0.5 h-full bg-gray-100" />
            {container(personalities[3])}
          </div>
        </div>
      </div>
      <div className="flex mt-2 ml-12">
        <div className="flex flex-col items-center justify-center w-1/2">
          <p className="text-gray-600">{t('personality.taskOriented')}</p>
          <p className="text-sm text-gray-300">{t('personality.slow')}</p>
        </div>
        <div className="flex flex-col items-center justify-center w-1/2">
          <p className="text-gray-600">{t('personality.goalOriented')}</p>
          <p className="text-sm text-gray-300">{t('personality.fast')}</p>
        </div>
      </div>
    </div>
  )
}

PersonalityPicker.propTypes = {
  initialPersonality: PropTypes.object,
  onPersonalitySelected: PropTypes.func.isRequired,
}
