import React, { Fragment } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Listbox, Transition } from '@headlessui/react'
import { ArrowLeftIcon, UserAddIcon, CheckIcon } from '@heroicons/react/outline'
import useTranslation from '../../../hooks/useTranslation'
import Assignee from './assignee'

export default function Assignees({
  customerName,
  assignees,
  user,
  organization,
  onAdd,
  onRemove,
}) {
  const t = useTranslation()

  const selectUser = (id) => {
    const user = (organization?.users || []).find((u) => u.id === id)
    onAdd(user)
  }

  const availableUsers = () => {
    const assignedUserIds = (assignees || []).map((a) => a.id)

    return (organization?.users || []).filter(
      (user) => assignedUserIds.includes(user.id) === false,
    )
  }

  return (
    <>
      <div className="relative z-0 flex items-center -space-x-1">
        {assignees.map(({ id, short_name }, index) => {
          const zIndex = assignees.length - index

          return (
            <Assignee
              key={id}
              zIndex={zIndex}
              shortName={short_name}
              onRemove={() => onRemove(id)}
            />
          )
        })}
        {availableUsers().length > 0 && (
          <Listbox onChange={selectUser}>
            {({ open }) => (
              <>
                <Listbox.Button className="relative z-0 inline-flex items-center justify-center w-8 h-8 transition rounded-full group bg-gray-50 ring-2 ring-gray-200 hover:ring-gray-600">
                  <span className="text-xs font-medium leading-none text-gray-300 transition group-hover:text-gray-600">
                    <UserAddIcon className="w-5 h-5" />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Listbox.Options className="absolute z-10 py-1 overflow-auto text-base -translate-y-full bg-white rounded-md shadow-lg -top-2 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {availableUsers().map((orgUser) => (
                      <Listbox.Option
                        key={orgUser.id}
                        className={({ active }) =>
                          cx(
                            active
                              ? 'text-white bg-indigo-500'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          )
                        }
                        value={orgUser.id}>
                        {({ selected, active }) => (
                          <>
                            <div className="flex">
                              <span
                                className={cx(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'truncate',
                                )}>
                                {orgUser.name}
                              </span>
                              <span
                                className={cx(
                                  active ? 'text-indigo-200' : 'text-gray-500',
                                  'ml-2 truncate',
                                )}>
                                {user.id === orgUser.id
                                  ? 'You'
                                  : orgUser.short_name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={cx(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                )}>
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </Listbox>
        )}
      </div>
      {assignees && assignees.length === 0 && (
        <div className="flex items-center ml-1 text-gray-400">
          <ArrowLeftIcon className="w-4 h-4 text-gray-300" />
          <p className="ml-1 text-xs w-fit">
            {t('customerModal.assignees.hint', { customerName })}
          </p>
        </div>
      )}
    </>
  )
}

Assignees.propTypes = {
  customerName: PropTypes.string,
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      short_name: PropTypes.string,
    }),
  ),
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  organization: PropTypes.shape({
    users: PropTypes.array,
  }),
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
}
