import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, CheckCircleIcon } from '@heroicons/react/outline'
import useUser from '../../hooks/useUser'
import useDate from '../../hooks/useDate'
import useTranlation from '../../hooks/useTranslation'

export default function FollowUp({
  bgColor,
  textColor,
  accentColor,
  user,
  followUp,
  onUpdatePriority,
  onShowCalendar,
  onUpdateUser,
  onDone,
}) {
  const [onHoverDone, setHoverDone] = useState(false)
  const [finishedFollowUp, setFinishedFollowUp] = useState(false)
  const [hoverPriority, setHoverPriority] = useState(false)
  const { useGetOrganization, useGetCurrentOrganizationId } = useUser()
  const { currentOrganizationId } = useGetCurrentOrganizationId({ user })
  const { organization } = useGetOrganization({
    organizationId: currentOrganizationId,
  })
  const { parse } = useDate()
  const t = useTranlation()

  const colorForPriority = (priority) => {
    switch (priority) {
      case 'high':
        return 'bg-red-300'
      case 'medium':
        return 'bg-yellow-300'
      case 'low':
        return 'bg-green-600'
    }
  }

  const textColorForPriority = (priority) => {
    switch (priority) {
      case 'high':
        return 'text-red-500'
      case 'medium':
        return 'text-yellow-500'
      case 'low':
        return 'text-green-600'
    }
  }

  useEffect(() => {
    if (!finishedFollowUp) {
      return
    }
    setTimeout(() => {
      onDone(followUp)
      setFinishedFollowUp(false)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedFollowUp])

  const getIcon = () => {
    if (finishedFollowUp) {
      return (
        <CheckCircleIcon className="w-6 h-6 mr-1 text-blue-300 bg-blue-800 rounded-full cursor-pointer" />
      )
    }

    if (onHoverDone) {
      return (
        <CheckCircleIcon
          onClick={() => setFinishedFollowUp(true)}
          className="w-6 h-6 mr-1 text-blue-300 bg-blue-800 rounded-full cursor-pointer"
        />
      )
    }

    return <CheckCircleIcon className="w-6 h-6 mr-1 cursor-pointer" />
  }

  const followUpUser =
    typeof followUp.user === 'string'
      ? JSON.parse(followUp.user)
      : followUp.user

  const updatePriority = (priority) => {
    onUpdatePriority(priority)
    setHoverPriority(false)
  }

  const selectUser = (userId) => {
    onUpdateUser(organization?.users.find((u) => u.id === userId))
  }

  return (
    <div
      key={followUp.id}
      className={`flex items-center justify-between px-2 py-2 
      bg-white border ${bgColor} ${textColor} rounded-lg`}>
      <div className="flex items-center">
        <div
          onMouseOver={() => setHoverDone(true)}
          onMouseLeave={() => setHoverDone(false)}>
          {getIcon()}
        </div>
        <p
          className={cx('', {
            'line-through': finishedFollowUp,
          })}>
          <Listbox value={followUpUser.id} onChange={selectUser}>
            {({ open }) => (
              <>
                <Listbox.Button
                  className={cx('', {
                    'bg-blue-600 rounded-full px-2 text-white':
                      !finishedFollowUp,
                    'line-through': finishedFollowUp,
                  })}>
                  <span className="inline-flex w-full truncate">
                    <span className="truncate">
                      {user.id === followUpUser.id
                        ? t('customerModal.followUp.you')
                        : followUpUser.short_name}
                    </span>
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Listbox.Options className="absolute z-10 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {organization?.users.map((orgUser) => (
                      <Listbox.Option
                        key={orgUser.id}
                        className={({ active }) =>
                          cx(
                            active
                              ? 'text-white bg-indigo-500'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          )
                        }
                        value={orgUser.id}>
                        {({ selected, active }) => (
                          <>
                            <div className="flex">
                              <span
                                className={cx(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'truncate',
                                )}>
                                {orgUser.name}
                              </span>
                              <span
                                className={cx(
                                  active ? 'text-indigo-200' : 'text-gray-500',
                                  'ml-2 truncate',
                                )}>
                                {user.id === orgUser.id
                                  ? 'You'
                                  : orgUser.short_name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={cx(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                )}>
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </Listbox>
          <span
            className={cx('', {
              'line-through': finishedFollowUp,
            })}>
            {' '}
            {user.id === followUpUser.id
              ? t('customerModal.followUp.text')
              : t('customerModal.followUp.text_otherPerson')}{' '}
          </span>
          <button
            className={cx(`text-sm ${accentColor}`, {
              'bg-blue-600 rounded-full px-2 text-white': !finishedFollowUp,
              'line-through decoration-indigo-500': finishedFollowUp,
            })}
            onClick={onShowCalendar}>
            {parse(followUp.date).toLocaleDateString()}
          </button>
        </p>
      </div>
      <div className="relative flex items-center">
        {hoverPriority === true && (
          <div
            className="absolute right-0 flex items-center px-4 py-2 space-x-2 bg-white rounded-full"
            onMouseLeave={() => setHoverPriority(false)}>
            <p className="mr-4">{t('customerModal.followUp.priority.text')}</p>
            <button
              className={cx('', textColorForPriority('high'))}
              onClick={() => updatePriority('high')}>
              {t('customerModal.followUp.priority.high')}
            </button>
            <button
              className={cx('', textColorForPriority('medium'))}
              onClick={() => updatePriority('medium')}>
              {t('customerModal.followUp.priority.medium')}
            </button>
            <button
              className={cx('', textColorForPriority('low'))}
              onClick={() => updatePriority('low')}>
              {t('customerModal.followUp.priority.low')}
            </button>
          </div>
        )}
        <button
          className={cx(
            'w-3 h-3 rounded-full',
            colorForPriority(followUp.priority),
          )}
          onMouseEnter={() => setHoverPriority(true)}
        />
      </div>
    </div>
  )
}

FollowUp.propTypes = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  accentColor: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  followUp: PropTypes.shape({
    id: PropTypes.string,
    priority: PropTypes.string,
    user: PropTypes.object,
    date: PropTypes.string,
  }),
  onUpdatePriority: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onShowCalendar: PropTypes.func,
  onDone: PropTypes.func,
}
