import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import useDate from '../../hooks/useDate'
import Day from './day'
import QuickReschedule from './quickDrop'

// eslint-disable-next-line react-hooks/rules-of-hooks
const { dateString, getMonthName, getDayName, addDays } = useDate()

const months = [
  getMonthName(0),
  getMonthName(1),
  getMonthName(2),
  getMonthName(3),
  getMonthName(4),
  getMonthName(5),
  getMonthName(6),
  getMonthName(7),
  getMonthName(8),
  getMonthName(9),
  getMonthName(10),
  getMonthName(11),
  getMonthName(12),
]

const yearForDate = (date) => {
  return date.getFullYear()
}

const monthForDate = (date) => {
  return date.getMonth()
}

const getAllDaysInMonth = (month, year) => {
  var date = new Date(Date.UTC(year, month, 1))
  var days = []
  while (date.getUTCMonth() === month) {
    days.push(new Date(date))
    date.setUTCDate(date.getUTCDate() + 1)
  }

  return days
}

const getFormattedDates = (currentDate, month, year, selectedDay) => {
  const allDaysInMonth = getAllDaysInMonth(month, year)
  const firstDay = allDaysInMonth[0].getDay()
  const lastDay = allDaysInMonth[allDaysInMonth.length - 1].getDay()
  // get all the days from the first day of the month to monday
  let daysOfPreviousMonth = []

  if (firstDay >= 0) {
    const day = firstDay === 0 ? 7 : firstDay
    const yearWithRollOver = month - 1 === -1 ? year - 1 : year
    const monthWithRollOver = month - 1 === -1 ? 11 : month - 1
    const previousMonthDays = getAllDaysInMonth(
      monthWithRollOver,
      yearWithRollOver,
    )
    daysOfPreviousMonth = previousMonthDays
      .slice(previousMonthDays.length - day + 1)
      .map((date) => {
        return {
          _date: date,
          date: dateString(date),
          isToday: false,
          isCurrentMonth: false,
          isSelected: false,
          isPreviousMonth: true,
        }
      })
  }

  let daysOfNextMonth = []

  if (lastDay <= 6) {
    const yearWithRollOver = month + 1 === 12 ? year + 1 : year
    const monthWithRollOver = month + 1 === 12 ? 0 : month + 1
    daysOfNextMonth = getAllDaysInMonth(monthWithRollOver, yearWithRollOver)
      .slice(0, 7 - lastDay)
      .map((date) => {
        return {
          _date: date,
          date: dateString(date),
          isToday: false,
          isCurrentMonth: false,
          isSelected: false,
          isNextMonth: true,
        }
      })
  }

  const mappedDays = allDaysInMonth.map((date) => {
    return {
      _date: date,
      date: dateString(date),
      isToday:
        date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear(),
      isCurrentMonth: true,
      isSelected:
        date.getDate() === selectedDay.getDate() &&
        date.getMonth() === selectedDay.getMonth() &&
        date.getFullYear() === selectedDay.getFullYear(),
    }
  })

  return daysOfPreviousMonth.concat(mappedDays).concat(daysOfNextMonth)
}

export default function Calendar({ startDate, onDropOnDate, onDateSelected }) {
  const [days, setDays] = useState([])
  const [currentYear, setCurrentYear] = useState(yearForDate(startDate))
  const [currentMonth, setCurrentMonth] = useState(monthForDate(startDate))
  const [selectedDate, setSelectedDate] = useState(startDate)

  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const goToPreviousMonth = () => {
    const date = new Date()
    const previousMonth = currentMonth - 1

    if (previousMonth === -1) {
      date.setDate(1)
      date.setMonth(11)
      date.setFullYear(currentYear - 1)
    } else {
      date.setDate(1)
      date.setMonth(currentMonth - 1)
      date.setFullYear(currentYear)
    }
    setCurrentMonth(monthForDate(date))
    setCurrentYear(yearForDate(date))
  }

  const goToNextMonth = () => {
    const date = new Date()
    const nextMonth = currentMonth + 1

    if (nextMonth === 12) {
      date.setDate(1)
      date.setMonth(0)
      date.setFullYear(currentYear + 1)
    } else {
      date.setDate(1)
      date.setMonth(currentMonth + 1)
      date.setFullYear(currentYear)
    }
    setCurrentMonth(monthForDate(date))
    setCurrentYear(yearForDate(date))
  }

  useEffect(() => {
    setDays(
      getFormattedDates(startDate, currentMonth, currentYear, selectedDate),
    )
  }, [startDate, currentMonth, currentYear, selectedDate])

  const onDrop = (e, date) => {
    e.preventDefault()
    const followUp = JSON.parse(e.dataTransfer.getData('text/plain'))
    console.log(date)
    onDropOnDate(date, followUp)
  }

  return (
    <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={goToPreviousMonth}>
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
        </button>
        <div className="flex-auto font-semibold">
          {months[currentMonth]}{' '}
          <span className="text-sm text-gray-400">({currentYear})</span>
        </div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={goToNextMonth}>
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
      <div className="grid grid-cols-7 mt-6 text-xs leading-6 text-gray-500">
        <div>{getDayName(1)}</div>
        <div>{getDayName(2)}</div>
        <div>{getDayName(3)}</div>
        <div>{getDayName(4)}</div>
        <div>{getDayName(5)}</div>
        <div>{getDayName(6)}</div>
        <div>{getDayName(0)}</div>
      </div>
      <div className="grid grid-cols-7 gap-px mt-2 text-sm bg-gray-200 rounded-lg shadow isolate ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <Day
            key={day.date}
            day={day}
            days={days}
            index={dayIdx}
            onDrop={(e) => onDrop(e, day.date)}
            onDateSelected={(date) => {
              setSelectedDate(date)
              onDateSelected(date)
            }}
            onGoToNextMonth={goToNextMonth}
            onGoToPreviousMonth={goToPreviousMonth}
          />
        ))}
      </div>
      {onDropOnDate && (
        <p className="mt-2 text-xs text-gray-400">{t('calendar.hint')}</p>
      )}
      <div className="flex w-full mt-8 mb-2 items-center justify-center">
        <QuickReschedule
          onDrop={(e, days) => {
            e.preventDefault()
            const followUp = JSON.parse(e.dataTransfer.getData('text/plain'))
            const date = dateString(addDays(days, followUp.date))
            console.log(date)
            onDropOnDate(date, followUp)
          }}
        />
      </div>
    </div>
  )
}

Calendar.propTypes = {
  startDate: PropTypes.any,
  onDropOnDate: PropTypes.func,
  onDateSelected: PropTypes.func.isRequired,
}
