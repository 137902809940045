export default function useSortTypes() {
  const SORT_TYPES = [
    {
      id: 'name',
      direction: 'asc',
      isActive: true,
    },
    {
      id: 'lastUpdate',
      direction: 'asc',
      isActive: false,
    },
    {
      id: 'nextFollowUp',
      direction: 'asc',
      isActive: false,
    },
  ]

  return { SORT_TYPES }
}
