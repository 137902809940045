import { useRef, useState, useEffect, useCallback } from 'react'
import algoliasearch from 'algoliasearch'
import { useDebouncedValue } from '@mantine/hooks'

export default function useSearch({
  value,
  currentOrganizationId,
  indexName,
  searchApiKey,
  debounceInMs = 500,
}) {
  const searchClient = useRef(
    algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID, searchApiKey),
  )
  const index = useRef(searchClient.current.initIndex(indexName))
  const [hits, setHits] = useState([])
  const [debounced] = useDebouncedValue(value, debounceInMs)

  useEffect(() => {
    if (!debounced) {
      return
    }
    search(debounced)
  }, [debounced, search])

  useEffect(() => {
    searchClient.current = algoliasearch(
      process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
      searchApiKey,
    )
    index.current = searchClient.current.initIndex(indexName)
  }, [indexName, searchApiKey])

  const search = useCallback(
    async (query) => {
      const res = await index.current.search(query, {
        hitsPerPage: query === '' ? 10 : 1000,
        filters: `visible_by:${currentOrganizationId}`,
      })
      setHits(res.hits)
    },
    [currentOrganizationId],
  )

  return {
    hits,
  }
}
