import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import usePersonalities from '../../hooks/usePersonalities'
import useDate from '../../hooks/useDate'
import { useApp } from '../../hooks/useAppContext'

const colors = {
  low: 'bg-green-300',
  medium: 'bg-yellow-300',
  high: 'bg-red-300',
}

export default function ListItem({ item, renderSubtitle }) {
  const { router } = useApp()
  const { getById } = usePersonalities()
  const [dragging, setDragging] = useState(false)
  const onDragEnter = (e, item) => {
    setDragging(true)
    e.dataTransfer.setDragImage(document.getElementById(item.customer.id), 0, 0)
    e.dataTransfer.setData('text/plain', JSON.stringify(item))
  }

  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })
  const { difference } = useDate()

  const isOverDue = difference(new Date(), item.date) > 0

  return (
    <li
      key={item.id}
      draggable
      onDragStart={(e) => onDragEnter(e, item)}
      onDragEnd={() => setDragging(false)}
      onClick={() =>
        router.push(`?c=${item.customer.id}`, `/c/${item.customer.id}`, {
          shallow: true,
        })
      }
      className={cx(
        'transition relative flex items-center py-4 lg:px-2 space-x-6 cursor-pointer xl:static hover:bg-gray-50 rounded-2xl',
        { 'opacity-30': dragging },
      )}>
      {item.priority && (
        <div className={cx('h-3 w-3 rounded-full', colors[item.priority])} />
      )}
      <div
        id={item.customer.id}
        className="flex items-center justify-center w-16 h-16 text-4xl text-center rounded-full bg-gray-50">
        {getById(item.customer.personality).icon}
      </div>
      <div className="flex-auto">
        <div className="flex items-center">
          <h3 className="flex-grow-0 pr-10 font-semibold text-gray-900 xl:pr-0">
            {item.customer.name}
          </h3>
          {isOverDue && (
            <p className="ml-2 text-xs text-red-300">
              {t('all.list.overdue', {
                days: difference(new Date(), item.date),
                count: difference(new Date(), item.date),
              })}
            </p>
          )}
        </div>
        <dl className="flex flex-col mt-2 text-gray-500 xl:flex-row">
          <div className="flex items-start space-x-3">
            <dt className="mt-0.5 text-gray-400">
              <span className="sr-only">Last interaction</span>
              {renderSubtitle(item)}
            </dt>
          </div>
        </dl>
      </div>
    </li>
  )
}

ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    customer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      personality: PropTypes.string,
    }).isRequired,
    priority: PropTypes.string,
    last_interaction: PropTypes.shape({
      type: PropTypes.string,
      created_at: PropTypes.string,
    }),
  }),
  renderSubtitle: PropTypes.func,
}
