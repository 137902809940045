import React from 'react'
import {
  MailIcon,
  PhoneIcon,
  LocationMarkerIcon,
  SparklesIcon,
  ChatIcon,
  AtSymbolIcon,
} from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

export default function useInteractionTypes({ interaction } = {}) {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const icon = (type = interaction.type, classnames = '') => {
    switch (type) {
      case 'call':
        return <PhoneIcon className={cx('w-5 h-5 text-zinc-800', classnames)} />
      case 'mail':
        return <MailIcon className={cx('w-5 h-5 text-zinc-800', classnames)} />
      case 'email':
        return (
          <AtSymbolIcon className={cx('w-5 h-5 text-zinc-800', classnames)} />
        )
      case 'inPerson':
        return (
          <LocationMarkerIcon
            className={cx('w-5 h-5 text-zinc-800', classnames)}
          />
        )
      case 'favor':
        return (
          <SparklesIcon className={cx('w-5 h-5 text-zinc-800', classnames)} />
        )
      case 'text':
        return <ChatIcon className={cx('w-5 h-5 text-zinc-800', classnames)} />
    }
  }

  const action = (type = interaction.type) => {
    return t(`interaction.${type}`)
  }

  return {
    icon,
    action,
  }
}
