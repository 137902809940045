import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server'
import { StarIcon as EmptyStarIcon } from '@heroicons/react/outline'
import { StarIcon as FilledStarIcon } from '@heroicons/react/solid'
import usePersonalities from '../../hooks/usePersonalities'
import PersonalityPicker from '../CreateCustomerModal/personalityPicker'

export default function PersonalityHeader({
  customer,
  onUpdatePersonality,
  onAddToFavorites,
  onRemoveFromFavorites,
}) {
  const [hoverPersonality, setHoverPersonality] = useState(false)
  const { getById } = usePersonalities()
  const getPersonality = () => getById(customer?.personality)

  const updatePersonality = (personality) => {
    onUpdatePersonality(personality)
    setHoverPersonality(false)
  }

  return (
    <div className="grid items-center w-full grid-cols-3">
      <ul
        className="text-xs text-gray-400"
        data-html={true}
        data-tip={ReactDOMServer.renderToString(
          getPersonality()?.keyIndicators.map((i) => <li key={i}>{i}</li>),
        )}
        data-for="indicators">
        {getPersonality()?.shortKeyIndicators.map((i) => (
          <li key={i}>{i}</li>
        ))}
      </ul>
      <div className="relative flex items-center px-4 py-4 bg-white border border-gray-300 rounded-full justify-self-center">
        <div className="absolute right-0 z-20 bg-white border border-gray-200 rounded-md cursor-pointer -top-1">
          {customer?.favorite ? (
            <FilledStarIcon
              className="w-6 h-6 text-yellow-500"
              onClick={onRemoveFromFavorites}
            />
          ) : (
            <EmptyStarIcon
              className="w-6 h-6 text-gray-300"
              onClick={onAddToFavorites}
            />
          )}
        </div>
        {hoverPersonality === true && (
          <div
            className="absolute lg:min-w-[450px] z-20 flex items-center px-8 py-8 bg-white border border-gray-300 rounded-md shadow-md top-8 -left-[150px] lg:-left-[200px]"
            onMouseLeave={() => setHoverPersonality(false)}>
            <PersonalityPicker
              initialPersonality={getById(customer?.personality)}
              onPersonalitySelected={updatePersonality}
            />
          </div>
        )}
        <p
          className="text-6xl text-center cursor-pointer"
          onClick={() => setHoverPersonality((old) => !old)}>
          {getPersonality()?.icon || '👀'}
        </p>
      </div>
      <ul
        className="text-xs text-right text-gray-400"
        data-html={true}
        data-tip={ReactDOMServer.renderToString(
          getPersonality()?.recommendations.map((r) => <li key={r}>{r}</li>),
        )}
        data-for="recommendations">
        {getPersonality()
          ?.shortRecommendations.slice(0, 4)
          .map((r) => (
            <li key={r}>{r}</li>
          ))}
      </ul>
    </div>
  )
}

PersonalityHeader.propTypes = {
  customer: PropTypes.shape({
    personality: PropTypes.string,
    favorite: PropTypes.boolean,
  }),
  onUpdatePersonality: PropTypes.func.isRequired,
  onAddToFavorites: PropTypes.func.isRequired,
  onRemoveFromFavorites: PropTypes.func.isRequired,
}
