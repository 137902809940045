import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useClickOutside, useDebouncedValue } from '@mantine/hooks'
import { PlusIcon, ArrowLeftIcon } from '@heroicons/react/outline'
import useTranslation from '../../hooks/useTranslation'
import useInteractionTypes from '../../hooks/useInteractionTypes'

export default function InteractionMenu({
  isNew,
  onPicked,
  initialButtonType,
  showHint,
  customerName,
}) {
  const [hoverTypeMenu, setHoverTypeMenu] = useState()
  const t = useTranslation()
  const { icon } = useInteractionTypes()
  const [debouncedHoverTypeMenu] = useDebouncedValue(hoverTypeMenu, 25)
  const ref = useClickOutside(() => {
    setHoverTypeMenu(false)
  })

  const onPick = (type) => {
    onPicked(type)
    setHoverTypeMenu(false)
  }

  return (
    <div className="flex flex-col w-full bg-white">
      <div
        ref={ref}
        className="flex pt-1"
        onMouseLeave={() => {
          setHoverTypeMenu(false)
        }}>
        <div className="relative flex flex-col justify-start">
          {isNew && (
            <button
              onMouseOver={() => {
                setHoverTypeMenu(true)
              }}
              onClick={() => {
                if (debouncedHoverTypeMenu) {
                  setHoverTypeMenu(false)
                } else {
                  setHoverTypeMenu(true)
                }
              }}
              className={cx(
                'group transform flex bg-zinc-300 flex-col items-center justify-center w-8 h-8 rounded-full',
                {
                  'hover:bg-green-300': isNew && !hoverTypeMenu,
                  'hover:bg-green-gray': hoverTypeMenu && isNew,
                  'hover:bg-gray-400': hoverTypeMenu && !isNew,
                },
              )}>
              <PlusIcon
                className={cx('text-zinc-800 w-5 h-5', {
                  'rotate-45 group-hover:text-gray-800': hoverTypeMenu && isNew,
                })}
              />
            </button>
          )}
          {initialButtonType && (
            <button
              onMouseOver={() => {
                setHoverTypeMenu(true)
              }}
              onClick={() => {
                if (debouncedHoverTypeMenu) {
                  setHoverTypeMenu(false)
                } else {
                  setHoverTypeMenu(true)
                }
              }}
              className={cx(
                'group transform flex bg-zinc-300 flex-col items-center justify-center w-8 h-8 rounded-full',
                {
                  'hover:bg-green-300': isNew && !debouncedHoverTypeMenu,
                  'hover:bg-green-gray': debouncedHoverTypeMenu && isNew,
                  'hover:bg-gray-400': debouncedHoverTypeMenu && !isNew,
                },
              )}>
              {icon(initialButtonType)}
            </button>
          )}
          <div className="absolute w-0.5 h-4 left-[15px] bg-gray-100 top-8"></div>
        </div>
        {debouncedHoverTypeMenu && (
          <div className="z-10 flex items-center pl-2 space-x-1 overflow-visible bg-white">
            {initialButtonType !== 'call' && (
              <button
                className="flex flex-col items-center justify-center w-8 h-8 transition rounded-full text-zinc-600 bg-zinc-300 hover:scale-110 hover:bg-green-300"
                onClick={() => onPick('call')}>
                {icon('call', 'hover:text-green-800')}
              </button>
            )}
            {initialButtonType !== 'email' && (
              <button
                className="flex flex-col items-center justify-center w-8 h-8 transition rounded-full text-zinc-600 bg-zinc-300 hover:scale-110 hover:bg-green-300"
                onClick={() => onPick('email')}>
                {icon('email', 'hover:text-green-800')}
              </button>
            )}
            {initialButtonType !== 'text' && (
              <button
                className="flex flex-col items-center justify-center w-8 h-8 transition rounded-full text-zinc-600 bg-zinc-300 hover:scale-110 hover:bg-green-300"
                onClick={() => onPick('text', 'hover:text-green-800')}>
                {icon('text')}
              </button>
            )}
            {initialButtonType !== 'inPerson' && (
              <button
                className="flex flex-col items-center justify-center w-8 h-8 transition rounded-full text-zinc-600 bg-zinc-300 hover:scale-110 hover:bg-green-300"
                onClick={() => onPick('inPerson', 'hover:text-green-800')}>
                {icon('inPerson')}
              </button>
            )}
            {initialButtonType !== 'mail' && (
              <button
                className="flex flex-col items-center justify-center w-8 h-8 transition rounded-full text-zinc-600 bg-zinc-300 hover:scale-110 hover:bg-green-300"
                onClick={() => onPick('mail', 'hover:text-green-800')}>
                {icon('mail')}
              </button>
            )}
          </div>
        )}
        {showHint && !debouncedHoverTypeMenu && (
          <div className="absolute flex text-gray-400 top-5 left-8 md:left-16">
            <ArrowLeftIcon className="w-4 h-4 text-gray-300" />
            <p className="ml-1 text-xs w-fit">
              {t('customerModal.interaction.emptyState', { customerName })}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

InteractionMenu.propTypes = {
  onPicked: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  initialButtonType: PropTypes.string,
  onLeaveTypeMenu: PropTypes.func,
  showHint: PropTypes.bool.isRequired,
  customerName: PropTypes.string,
}

InteractionMenu.defaultProps = {
  isNew: true,
  showHint: false,
}
