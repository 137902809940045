import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslation from '../../hooks/useTranslation'

export default function QuickReschedule({ onDrop }) {
  const t = useTranslation()
  const days = ['1', '3', '7', '14', '21', '30', '60', '90', '180', '365']

  return (
    <div className="flex flex-col sm:flex-row space-x-2 items-center space-y-2">
      <span className="text-gray-300 text-sm"></span>
      <div className="grid gap-x-2 gap-y-2 grid-cols-4 sm:grid-cols-5">
        {days.map((day) => {
          return <QuickDrop key={day} day={day} onDrop={onDrop} />
        })}
      </div>
      <span className="text-gray-300 text-sm">
        {t('calendar.scheduleInFuture')}
      </span>
    </div>
  )
}

QuickReschedule.propTypes = {
  onDrop: PropTypes.func.isRequired,
}

function QuickDrop({ day, onDrop }) {
  const [dragOver, setDragOver] = useState(false)
  const onDragOver = (e) => {
    e.preventDefault()
    setDragOver(true)
  }

  return (
    <span
      onDrop={(e) => {
        setDragOver(false)
        onDrop(e, day)
      }}
      onDragLeave={() => setDragOver(false)}
      onDragOver={onDragOver}
      className={cx(
        'mx-auto w-full text-center rounded-full bg-gray-50 px-2 py-2 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10',
        {
          'bg-green-300 text-green-800': dragOver,
        },
      )}>
      {day}
    </span>
  )
}

QuickDrop.propTypes = {
  day: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
}
