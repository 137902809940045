import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Calendar from '../Calendar'
import useCustomer from '../../hooks/useCustomer'
import useUser from '../../hooks/useUser'
import useInteractionTypes from '../../hooks/useInteractionTypes'
import useDate from '../../hooks/useDate'
import EmptyState from './emptyState'
import ListItem from './listItem'
import All from './all'

function dateDiffInDaysText(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export default function DayFollowUps({
  currentOrganizationId,
  user,
  modalOpen,
  tab,
  followUpUser,
  listMode,
  currentListUserId,
  onCreateFollowUp,
  onCreateContact,
  currentDate,
  onDateChanged,
}) {
  const containerRef = useRef()
  const { useGetUserFollowUps } = useUser()
  const { useUpdateFollowUp, useCreateFollowUp } = useCustomer()
  const { updateFollowUp, isSuccess: hasUpdatedFollowUp } = useUpdateFollowUp()
  const { createFollowUp, isSuccess: hasCreatedFollowUp } = useCreateFollowUp()
  const { follow_ups: followUps } = useGetUserFollowUps({
    userId: followUpUser?.id,
    organizationId: currentOrganizationId,
    date: currentDate,
  })
  const { action } = useInteractionTypes()
  const { parse, dateString } = useDate()
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const dayText = (difference) => {
    if (difference === 0) {
      return t('all.list.today')
    }

    if (difference < 0) {
      return t('all.list.timeAgoText', {
        days: Math.abs(difference),
        count: Math.abs(difference),
      })
    }

    return t('all.list.inTheFutureText', {
      days: Math.abs(difference),
      count: Math.abs(difference),
    })
  }

  const subtitleForItem = (item) => {
    if (!item.last_interaction) {
      if (tab === 'day') {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: t('all.list.firstInteraction'),
            }}
          />
        )
      }

      if (item.next_follow_up) {
        return (
          <div>
            {t('all.list.noInteractionsWithScheduled')}
            <span className="text-gray-600">
              {parse(item.next_follow_up.date).toLocaleDateString(undefined, {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}{' '}
            </span>
            <span className="text-gray-300">
              (
              {dayText(
                dateDiffInDaysText(new Date(), parse(item.next_follow_up.date)),
              )}
              )
            </span>
          </div>
        )
      }

      return <div>{t('all.list.noInteractions')}</div>
    }

    return (
      <>
        {t(`${`interaction.last_${item.last_interaction.type}`}`)}{' '}
        <span className="text-gray-600">
          {action(item.last_interaction.type)}
        </span>{' '}
        {t('all.list.on')}{' '}
        <span className="text-gray-600">
          {parse(item.last_interaction.created_at).toLocaleDateString(
            undefined,
            {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            },
          )}
        </span>{' '}
        <span className="text-gray-300">
          (
          {dayText(
            dateDiffInDaysText(
              new Date(),
              parse(item.last_interaction.created_at),
            ),
          )}
          )
        </span>{' '}
        {t('all.list.with')}{' '}
        {item.last_interaction.user.id === user?.id
          ? t('all.list.you')
          : item.last_interaction.user.short_name}
        {item.next_follow_up && (
          <span>
            , next follow up{' '}
            <span className="text-gray-600">
              {parse(item.next_follow_up.date).toLocaleDateString(undefined, {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}{' '}
            </span>
            <span className="text-gray-300">
              (
              {dayText(
                dateDiffInDaysText(new Date(), parse(item.next_follow_up.date)),
              )}
              )
            </span>
          </span>
        )}
      </>
    )
  }

  useEffect(() => {
    if (!hasCreatedFollowUp) {
      return
    }
    toast.success(t('toast.followUpCreated'), {
      style: {
        backgroundColor: '#86efac',
        color: '#166534',
      },
      iconTheme: {
        primary: '#166534',
        secondary: '#86efac',
      },
    })
  }, [hasCreatedFollowUp, t])

  useEffect(() => {
    if (!hasUpdatedFollowUp) {
      return
    }
    toast.success(t('toast.followUpUpdated'), {
      style: {
        backgroundColor: '#86efac',
        color: '#166534',
      },
      iconTheme: {
        primary: '#166534',
        secondary: '#86efac',
      },
    })
  }, [hasUpdatedFollowUp, t])

  return (
    <div className="mt-16 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:mt-4">
      {!modalOpen && <Toaster position="bottom-center" />}
      <Calendar
        startDate={new Date()}
        onDateSelected={onDateChanged}
        onDropOnDate={(date, item) => {
          if (tab === 'day' || item.next_follow_up) {
            const followUp = item.next_follow_up || item
            updateFollowUp({
              id: followUp.id,
              change: { date },
              organizationId: currentOrganizationId,
              currentDate: dateString(currentDate),
              customerId: followUp.customer.id,
            })
          } else {
            createFollowUp({
              customerId: item.customer.id,
              organizationId: currentOrganizationId,
              data: {
                date: date,
                priority: 'medium',
              },
            })
          }
        }}
      />
      {tab === 'list' ? (
        <div className="relative lg:col-span-7 xl:col-span-8">
          <div ref={containerRef} />
          <div
            className="px-1 overflow-x-hidden overflow-y-scroll"
            style={{ height: 'calc(100vh - 240px)' }}>
            <All
              containerRef={containerRef}
              currentOrganizationId={currentOrganizationId}
              userId={currentListUserId}
              listMode={listMode}
              onCreateContact={onCreateContact}
            />
          </div>
        </div>
      ) : (
        <ol
          className="overflow-x-hidden overflow-y-scroll text-sm leading-6 divide-y divide-gray-100 lg:col-span-7 xl:col-span-8"
          style={{ height: 'calc(100vh - 240px)' }}>
          {followUps?.map((resource) => (
            <ListItem
              key={resource.id}
              item={resource}
              renderSubtitle={subtitleForItem}
            />
          ))}
          {followUps?.length === 0 && (
            <div className="flex items-center justify-center w-full h-full">
              <EmptyState
                tab={tab}
                onClick={() => {
                  if (tab === 'day') {
                    onCreateFollowUp()
                  } else {
                    onCreateContact()
                  }
                }}
              />
            </div>
          )}
        </ol>
      )}
    </div>
  )
}

DayFollowUps.propTypes = {
  currentOrganizationId: PropTypes.string,
  user: PropTypes.object,
  modalOpen: PropTypes.bool,
  followUpUser: PropTypes.any,
  tab: PropTypes.string.isRequired,
  listMode: PropTypes.string.isRequired,
  currentListUserId: PropTypes.string.isRequired,
  onDateChanged: PropTypes.func,
  onCreateFollowUp: PropTypes.func,
  onCreateContact: PropTypes.func,
  currentDate: PropTypes.any,
}
