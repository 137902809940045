import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  CheckCircleIcon,
  EmojiHappyIcon,
  EmojiSadIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import useDate from '../../hooks/useDate'
import useInteractionTypes from '../../hooks/useInteractionTypes'
import useTranslation from '../../hooks/useTranslation'

export default function Highlight({
  bgColor,
  textColor,
  accentColor,
  interaction,
  highlight,
  onHighlightDone,
  isPositiveHighlight,
}) {
  const [onHoverDone, setHoverDone] = useState(false)
  const [finishedHighlight, setFinishedHighlight] = useState(false)
  const { parse } = useDate()
  const { action } = useInteractionTypes()
  const t = useTranslation()

  useEffect(() => {
    if (!finishedHighlight) {
      return
    }
    setTimeout(() => {
      onHighlightDone(highlight)
      setFinishedHighlight(false)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedHighlight])

  const getIcon = () => {
    if (finishedHighlight) {
      return isPositiveHighlight ? (
        <EmojiSadIcon className="w-6 h-6 mr-1" />
      ) : (
        <EmojiHappyIcon className="w-6 h-6 mr-1" />
      )
    }

    if (onHoverDone) {
      return isPositiveHighlight ? (
        <XCircleIcon
          onClick={() => setFinishedHighlight(true)}
          className="w-6 h-6 mr-1 cursor-pointer"
        />
      ) : (
        <CheckCircleIcon
          onClick={() => setFinishedHighlight(true)}
          className={`w-6 h-6 mr-1 bg-${textColor
            .split('-')
            .slice(1, undefined)
            .join('-')} text-${bgColor
            .split('-')
            .slice(1, undefined)
            .join('-')} rounded-full cursor-pointer`}
        />
      )
    }

    return isPositiveHighlight ? (
      <EmojiHappyIcon className="w-6 h-6 mr-1" />
    ) : (
      <CheckCircleIcon className="w-6 h-6 mr-1" />
    )
  }

  return (
    <div
      key={highlight.id}
      className={`flex justify-between items-center
      px-2 py-2 ${bgColor} ${textColor} rounded-lg`}>
      <div className="flex items-center">
        <div
          onMouseOver={() => setHoverDone(true)}
          onMouseLeave={() => setHoverDone(false)}>
          {getIcon()}
        </div>
        <p
          className={cx('', {
            'line-through': finishedHighlight,
          })}>
          {highlight.title}
        </p>
      </div>
      <button
        className={`text-sm ${accentColor}`}
        onClick={() =>
          document
            .getElementById(highlight.id)
            ?.scrollIntoView({ behavior: 'smooth' })
        }>
        {action(interaction?.type) || ''} {t('customerModal.highlights.from')}{' '}
        {parse(
          interaction?.date || interaction?.created_at,
        ).toLocaleDateString()}
      </button>
    </div>
  )
}

Highlight.propTypes = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  accentColor: PropTypes.string,
  highlight: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    created_at: PropTypes.any,
  }),
  interaction: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    date: PropTypes.any,
    created_at: PropTypes.any,
  }),
  onHighlightDone: PropTypes.func,
  isPositiveHighlight: PropTypes.bool,
}
