import React from 'react'
import PropTypes from 'prop-types'
import { PlusIcon } from '@heroicons/react/outline'
import useTabs from '../../hooks/useTabs'
import useDate from '../../hooks/useDate'
import Highlight from './highlight'
import FollowUp from './followUp'

export default function TabContent({
  isLoadingCustomer,
  user,
  customer,
  currentTab,
  onCreateFollowUp,
  onShowCalendar,
  onUpdateFollowUp,
  onDeleteFollowUp,
  onDone,
}) {
  const tabs = useTabs({ onScheduleFollowUp: onCreateFollowUp })
  const { parse } = useDate()
  const {
    bgColor,
    textColor,
    accentColor,
    emptyStateText,
    emojiComponent,
    buttonComponent,
    type,
  } = tabs[currentTab]

  if (isLoadingCustomer) {
    return null
  }

  const resource =
    type === 'followUp'
      ? customer?.follow_ups.sort(
          (a, b) => parse(b.date).getTime() - parse(a.date).getTime(),
        )
      : customer?.highlights
          ?.filter((h) => h.type === type)
          .sort((a, b) => {
            const interactionAIndex = customer.interactions.findIndex(
              (i) => i.id === a.interaction_id,
            )
            const interactionBIndex = customer.interactions.findIndex(
              (i) => i.id === b.interaction_id,
            )

            if (
              interactionAIndex === undefined ||
              interactionBIndex === undefined
            ) {
              return (
                parse(b.created_at).getTime() - parse(a.created_at).getTime()
              )
            }

            return interactionAIndex - interactionBIndex
          })

  if (resource?.length === 0 && !isLoadingCustomer) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full text-center">
        <div className="flex items-center justify-center mb-2">
          <p className="text-sm text-gray-500">{emptyStateText}</p>
          {emojiComponent}
        </div>
        {buttonComponent && buttonComponent}
      </div>
    )
  }

  if (!resource || resource?.length === 0) {
    return null
  }

  if (type === 'followUp') {
    const followUps = resource?.map((followUp) => {
      return (
        <FollowUp
          key={followUp.id}
          followUp={followUp}
          user={user}
          textColor={textColor}
          accentColor={accentColor}
          bgColor={bgColor}
          onShowCalendar={() => onShowCalendar(followUp)}
          onUpdateUser={(user) => {
            onUpdateFollowUp({
              id: followUp.id,
              user,
              change: { user_id: user.id },
            })
          }}
          onUpdatePriority={(priority) =>
            onUpdateFollowUp({
              id: followUp.id,
              change: { priority },
            })
          }
          onDone={() => onDeleteFollowUp({ id: followUp.id })}
        />
      )
    })

    const createFollowUpButton = (
      <div className="flex items-center justify-between px-2 py-2 text-blue-800 bg-blue-300 border rounded-lg justify-self-center w-fit">
        <button onClick={onCreateFollowUp}>
          <PlusIcon className="w-5 h-5 text-blue-800" />
        </button>
      </div>
    )

    return [...followUps, createFollowUpButton]
  }

  return resource?.map((highlight) => {
    return (
      <Highlight
        key={highlight.id}
        interaction={
          customer.interactions.filter(
            (i) => i.id === highlight.interaction_id,
          )[0]
        }
        highlight={highlight}
        textColor={textColor}
        accentColor={accentColor}
        bgColor={bgColor}
        onHighlightDone={onDone}
        isPositiveHighlight={type === 'favor'}
      />
    )
  })
}

TabContent.propTypes = {
  isLoadingCustomer: PropTypes.bool,
  customer: PropTypes.shape({
    highlights: PropTypes.array,
    followUps: PropTypes.array,
  }),
  onCreateFollowUp: PropTypes.func,
  currentTab: PropTypes.number,
}
