import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

export default function AutosizeTextarea({
  value,
  onChange,
  onBlur,
  className,
  maxiumRows = 500,
  fontSizeInRem = 1,
}) {
  const [textareaHeight, setTextareaHeight] = useState(1)
  const textareaRef = useRef()

  useEffect(() => {
    const height = textareaRef.current.scrollHeight
    const rowHeight = convertRemToPixels(fontSizeInRem)
    const trows = Math.ceil(height / rowHeight) - 1

    if (trows && textareaHeight && trows <= maxiumRows) {
      setTextareaHeight(trows)
    }
  }, [value, textareaRef, fontSizeInRem, maxiumRows, textareaHeight])

  const convertRemToPixels = (rem) => {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
  }

  return (
    <textarea
      ref={textareaRef}
      rows={textareaHeight}
      className={className}
      value={value}
      onChange={onChange}
      onBlur={onBlur}></textarea>
  )
}

AutosizeTextarea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  maxiumRows: PropTypes.number,
  fontSizeInRem: PropTypes.number,
}
