/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslation from '../../hooks/useTranslation'
import UserListBox from '../UserListBox'

export default function EverydayButton({
  tab,
  currentListUser,
  organization,
  user,
  onUserSelected,
  onTabChanged,
}) {
  const t = useTranslation()
  const availableUsers = () => {
    return [
      { id: 'all', name: t('followUps.list.all') },
      { id: 'all_favorites', name: t('followUps.list.all_favorites') },
      { id: 'my_favorites', name: t('followUps.list.my_favorites') },
      ...(organization?.users ?? []),
      { id: 'nobody', name: t('followUps.list.nobody') },
      { id: 'no_follow_up', name: t('followUps.list.no_follow_up') },
    ]
  }

  return (
    <span
      className={cx(
        'text-lg font-semibold hover:text-gray-800 cursor-pointer',
        {
          'text-gray-400': tab !== 'list',
        },
      )}
      onClick={() => onTabChanged('list')}>
      <UserListBox
        disabled={tab !== 'list'}
        value={currentListUser}
        users={availableUsers()}
        currentUser={user}
        onSelect={(id) =>
          onUserSelected(availableUsers().find((u) => u.id === id))
        }
      />{' '}
      {t('followUps.everybody')}
    </span>
  )
}

EverydayButton.propTypes = {
  tab: PropTypes.string,
  currentListUser: PropTypes.any,
  organization: PropTypes.any,
  user: PropTypes.any,
  onTabChanged: PropTypes.func,
  onUserSelected: PropTypes.func,
  onModeChanged: PropTypes.func,
}
