import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

export default function Day({
  day,
  index,
  days,
  onDrop,
  onDateSelected,
  onGoToNextMonth,
  onGoToPreviousMonth,
}) {
  const [dragOver, setDragOver] = useState(false)
  const onDragOver = (e) => {
    e.preventDefault()
    setDragOver(true)
  }

  return (
    <button
      onDrop={(e) => {
        onDrop(e, day.date)
        setDragOver(false)
      }}
      onDragLeave={() => setDragOver(false)}
      onDragOver={onDragOver}
      key={day.date}
      type="button"
      onClick={() => {
        if (day.isPreviousMonth) {
          onGoToPreviousMonth()
        }

        if (day.isNextMonth) {
          onGoToNextMonth()
        }
        onDateSelected(day._date)
      }}
      className={cx(
        'py-1.5 hover:bg-gray-100 focus:z-10',
        day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
        (day.isSelected || day.isToday) && 'font-semibold',
        day.isSelected && 'text-white',
        !day.isSelected &&
          day.isCurrentMonth &&
          !day.isToday &&
          'text-gray-900',
        !day.isSelected &&
          !day.isCurrentMonth &&
          !day.isToday &&
          'text-gray-400',
        day.isToday && !day.isSelected && 'text-indigo-600',
        index === 0 && 'rounded-tl-lg',
        index === 6 && 'rounded-tr-lg',
        index === days.length - 7 && 'rounded-bl-lg',
        index === days.length - 1 && 'rounded-br-lg',
        {
          'bg-green-300 text-green-800': dragOver && !day.isSelected,
          'bg-green-300 text-white': dragOver && day.isSelected,
        },
      )}>
      <time
        dateTime={day.date}
        className={cx(
          'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
          day.isSelected && day.isToday && 'bg-indigo-600',
          day.isSelected && !day.isToday && 'bg-gray-900',
        )}>
        {day.date.split('-').pop().replace(/^0/, '')}
      </time>
    </button>
  )
}

Day.propTypes = {
  day: PropTypes.object,
  index: PropTypes.number,
  days: PropTypes.array,
  onDrop: PropTypes.func.isRequired,
  onDateSelected: PropTypes.func.isRequired,
  onGoToNextMonth: PropTypes.func.isRequired,
  onGoToPreviousMonth: PropTypes.func.isRequired,
}
