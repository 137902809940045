import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

export default function Tabs({
  tabs,
  customer,
  currentTab,
  onChangeCurrentTab,
}) {
  const numberOfItemsForTab = (tab) => {
    switch (tab.type) {
      case 'issue':
        return customer?.highlights?.filter((h) => h.type === 'issue').length
      case 'favor':
        return customer?.highlights?.filter((h) => h.type === 'favor').length
      case 'info':
        return customer?.highlights?.filter((h) => h.type === 'info').length
      default:
        return customer?.follow_ups?.length
    }
  }

  return (
    <>
      {tabs.map((t, index) => (
        <div
          key={t.name}
          onClick={() => onChangeCurrentTab(index)}
          className={cx(
            `flex items-center justify-center py-2
            cursor-pointer`,
            {
              [t.bgColor]: index === currentTab,
              'border-b border-gray-200': index <= 1,
              'border-r border-gray-200': index % 2 === 0,
              'hover:bg-gray-200': index !== currentTab,
            },
          )}>
          <p
            className={cx('text-xs text-center lg:text-base', {
              [t.textColor]: index === currentTab,
              'text-gray-800': index !== currentTab,
            })}>
            {t.name}
          </p>
          <div
            className={cx(
              `flex items-center justify-center w-4 h-4 ml-1
              text-xs rounded-full`,
              {
                'text-gray-200 bg-gray-400': index !== currentTab,
                [t.badgeTextColor]: index === currentTab,
                [t.badgeBgColor]: index === currentTab,
                'opacity-0': numberOfItemsForTab(t) === undefined,
              },
            )}>
            {numberOfItemsForTab(t)}
          </div>
        </div>
      ))}
    </>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  customer: PropTypes.shape({
    highlights: PropTypes.array,
    follow_ups: PropTypes.array,
  }),
  currentTab: PropTypes.number,
  onChangeCurrentTab: PropTypes.func,
}
