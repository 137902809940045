import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { Listbox, Transition } from '@headlessui/react'
import useTranslation from '../../hooks/useTranslation'

export default function UserListBox({
  disabled,
  value,
  currentUser,
  users,
  onSelect,
}) {
  const t = useTranslation()

  const buttonSuffix = () => {
    if (
      value?.id === 'all' ||
      value?.id === 'nobody' ||
      value?.id === 'all_favorites' ||
      value?.id === 'my_favorites' ||
      value?.id === 'no_follow_up'
    ) {
      return ''
    }

    if (currentUser?.id === value?.id || !value) {
      return t('followUps.helping.currentUserSuffix')
    }

    return t('followUps.helping.otherUserSuffix')
  }

  return (
    <Listbox disabled={disabled} value={value?.id} onChange={onSelect}>
      {({ open }) => (
        <>
          <Listbox.Button
            className={cx(
              'cursor-pointer decoration-dashed underline-offset-2 bg-gray-50 border border-gray-300 rounded-full px-2',
            )}>
            <span className="inline-flex items-center w-full truncate">
              <span
                className={cx(
                  'truncate cursor-pointer decoration-indigo-500 ml-1 decoration-dashed underline-offset-2',
                )}>
                {currentUser?.id === value?.id || !value
                  ? t('followUps.helping.your')
                  : value.short_name || value.name}
                {buttonSuffix()}
              </span>
              <ChevronDownIcon className="w-5 h-5 text-gray-300" />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {users.map((orgUser) => (
                <Listbox.Option
                  key={orgUser.id}
                  className={({ active }) =>
                    cx(
                      active ? 'text-white bg-indigo-500' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9',
                    )
                  }
                  value={orgUser.id}>
                  {({ selected, active }) => (
                    <>
                      <div className="flex">
                        <span
                          className={cx(
                            selected ? 'font-semibold' : 'font-normal',
                            'truncate',
                          )}>
                          {orgUser.name}
                        </span>
                        <span
                          className={cx(
                            active ? 'text-indigo-200' : 'text-gray-500',
                            'ml-2 truncate',
                          )}>
                          {currentUser.id === orgUser.id
                            ? t('followUps.helping.you')
                            : orgUser.short_name}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={cx(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                          )}>
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  )
}

UserListBox.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.any,
  currentUser: PropTypes.any,
  users: PropTypes.array,
  button: PropTypes.node,
  onSelect: PropTypes.func,
}
