/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState, useEffect, useRef } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import ReactTooltip from 'react-tooltip'
import useCustomer from '../../hooks/useCustomer'
import useInteractionTypes from '../../hooks/useInteractionTypes'
import Spinner from '../../assets/spinner.svg'
import CTAButton from '../CTAButton'
import useTabs from '../../hooks/useTabs'
import Calendar from '../Calendar'
import useWindowSize from '../../hooks/useWindowSize'
import useDate from '../../hooks/useDate'
import useTranslation from '../../hooks/useTranslation'
import AutosizeTextarea from '../AutosizeTextarea'
import Interaction from './interaction'
import InteractionMenu from './interactionMenu'
import TabContent from './tabContent'
import Tabs from './tabs'
import PersonalityHeader from './personalityHeader'
import Assignees from './assignees'

export default function CustomerModal({
  user,
  organization,
  currentOrganizationId,
  open,
  customerId,
  onClose,
}) {
  const {
    useGetCustomer,
    useDeleteCustomer,
    useDeleteInteraction,
    useUpdateInteraction,
    useCreateInteraction,
    useUpdateCustomer,
    useDeleteHighlight,
    useCreateHighlight,
    useCreateFollowUp,
    useUpdateFollowUp,
    useDeleteFollowUp,
    useAddToFavorites,
    useDeleteFavorite,
    useAddAsignee,
    useRemoveAssignee,
  } = useCustomer({
    customerId,
  })
  const t = useTranslation()
  const { customer, isLoading: isLoadingCustomer } = useGetCustomer({
    organizationId: currentOrganizationId,
    customerId,
  })
  const lastAction = useRef()
  const {
    deleteCustomer,
    isLoading: isDeletetingCustomer,
    isSuccess: hasDeletedCustomer,
    isError: hasErroredDeletingCustomer,
  } = useDeleteCustomer()

  const {
    createInteraction,
    isSuccess: isDoneCreating,
    isLoading: isCreatingInteraction,
    isError: hasErroredCreatingInteraction,
  } = useCreateInteraction()
  const {
    deleteInteraction,
    isLoading: isDeletingInteraction,
    isError: hasErroredDeletingInteraction,
  } = useDeleteInteraction()
  const {
    updateCustomer,
    isLoading: isUpdatingCustomer,
    isError: hasErroredUpdatingCustomer,
  } = useUpdateCustomer()
  const {
    updateInteraction,
    isLoading: isUpdatingInteraction,
    isError: hasErroredUpdatingInteraction,
  } = useUpdateInteraction()
  const {
    deleteHighlight,
    isLoading: isDeletingHighlight,
    isError: hasErroredDeletingHighlight,
  } = useDeleteHighlight()
  const {
    createHighlight,
    isLoading: isCreatingHighlight,
    isError: hasErroredCreatingHighlight,
  } = useCreateHighlight()
  const {
    createFollowUp,
    isLoading: isCreatingFollowUp,
    isError: hasErroredCreatingFollowUp,
  } = useCreateFollowUp()
  const {
    updateFollowUp,
    isLoading: isUpdatingFollowUp,
    isError: hasErroredUpdatingFollowUp,
  } = useUpdateFollowUp()
  const {
    deleteFollowUp,
    isLoading: isDeletingFollowUp,
    isError: hasErroredDeletingFollowUp,
  } = useDeleteFollowUp()
  const {
    addToFavorites,
    isLoading: isAddingFavorite,
    isError: hasErroredAddingFavorite,
  } = useAddToFavorites()
  const {
    deleteFavorite,
    isLoading: isDeletingFavorite,
    isError: hasErroredDeletingFavorite,
  } = useDeleteFavorite()

  const { addAssignee, isLoading: isAddingAssignee } = useAddAsignee()
  const { removeAssignee, isLoading: isRemovingAssignee } = useRemoveAssignee()

  const [shouldStartEditing, setShouldStartEditing] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [customerName, setCustomerName] = useState(customer?.name || '-')
  const [customerInfo, setCustomerInfo] = useState(customer?.info || '')
  const [needsSavedInfo, setNeedsSavedInfo] = useState(false)
  const [editableInteraction, setEditableInteraction] = useState(null)
  const [editableFollowUp, setEditableFollowUp] = useState(null)
  const successButtonRef = useRef()
  const tabs = useTabs()
  const { action } = useInteractionTypes()
  const { isSmallDevice } = useWindowSize()
  const { parse, dateString } = useDate()

  const infoChanged = (e) => {
    setCustomerInfo(e.target.value)
  }

  const updatePersonality = (personality) => {
    updateCustomer({
      id: customerId,
      organizationId: currentOrganizationId,
      data: { personality },
    })
    lastAction.current = () => {
      updateCustomer({
        id: customerId,
        organizationId: currentOrganizationId,
        data: { personality },
      })
    }
  }

  const updateCustomerInfo = () => {
    if (!customer || customer?.info === customerInfo) {
      return
    }
    updateCustomer({
      id: customerId,
      organizationId: currentOrganizationId,
      data: { info: customerInfo },
    })
    lastAction.current = () => {
      updateCustomer({
        id: customerId,
        organizationId: currentOrganizationId,
        data: { info: customerInfo },
      })
    }
  }

  const updateCustomerName = () => {
    if (!customer || customer?.name === customerName) {
      return
    }
    updateCustomer({
      id: customerId,
      organizationId: currentOrganizationId,
      data: { name: customerName },
    })

    lastAction.current = () => {
      updateCustomer({
        id: customerId,
        organizationId: currentOrganizationId,
        data: { name: customerName },
      })
    }
  }

  useEffect(() => {
    setCustomerName(customer?.name || '-')
    setCustomerInfo(customer?.info || '')

    if (!customer) {
      setCurrentTab(0)
    }
  }, [customer])

  useEffect(() => {
    if (!open) {
      ReactTooltip.hide()
    }
  }, [open])

  const onHighlightDone = (highlight) => {
    deleteHighlight({
      id: highlight.id,
      customerId,
      organizationId: currentOrganizationId,
    })
    lastAction.current = () => {
      deleteHighlight({
        id: highlight.id,
        customerId,
        organizationId: currentOrganizationId,
      })
    }
  }

  const onAddFavorite = () => {
    addToFavorites({
      customerId,
      organizationId: currentOrganizationId,
    })
    lastAction.current = () => {
      addToFavorites({
        customerId,
        organizationId: currentOrganizationId,
      })
    }
  }

  const onDeleteFavorite = () => {
    deleteFavorite({
      customerId,
      organizationId: currentOrganizationId,
    })
    lastAction.current = () => {
      deleteFavorite({
        customerId,
        organizationId: currentOrganizationId,
      })
    }
  }

  const onDeleteUpdate = (interaction) => {
    toast(
      (_toast) => (
        <span className="text-center text-gray-400">
          <span>{t('customerModal.delete.title')}</span>
          <span className="font-bold text-gray-800">
            {' '}
            ({action(interaction.type)}){' '}
          </span>
          <span>{t('customerModal.delete.with')}</span>
          <span className="font-bold text-gray-800"> {customer.name}</span>
          <span>{t('customerModal.delete.delete')}</span>
          <br />
          <br />
          <span>{t('customerModal.delete.warning')}</span>
          <div className="flex items-center justify-center mt-4 space-x-4 justify-items-center">
            <button
              className="py-2 text-gray-400 hover:text-gray-800"
              onClick={() => toast.dismiss(_toast.id)}>
              {t('customerModal.delete.cancel')}
            </button>
            <button
              className="inline-flex justify-center flex-shrink-0 px-4 py-2 text-sm font-medium text-red-800 bg-red-300 border border-transparent rounded-md shadow-sm hover:text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={() => {
                toast.dismiss(_toast.id)
                deleteInteraction({
                  customerId,
                  organizationId: currentOrganizationId,
                  id: interaction.id,
                })
                lastAction.current = () => {
                  deleteInteraction({
                    customerId,
                    organizationId: currentOrganizationId,
                    id: interaction.id,
                  })
                }
              }}>
              {t('customerModal.delete.confirm')}
            </button>
          </div>
        </span>
      ),
      {
        duration: 10000,
      },
    )
  }

  const onDeleteCustomer = () => {
    toast(
      (_toast) => (
        <span className="text-center text-gray-400">
          <span>{t('customerModal.deleteCustomer.title')}</span>
          <span className="font-bold text-gray-800"> {customer?.name}</span>
          <span>{t('customerModal.deleteCustomer.delete')}</span>
          <br />
          <br />
          <span>{t('customerModal.deleteCustomer.warning')}</span>
          <div className="flex items-center justify-center mt-4 space-x-4 justify-items-center">
            <button
              className="py-2 text-gray-400 hover:text-gray-800"
              onClick={() => toast.dismiss(_toast.id)}>
              {t('customerModal.deleteCustomer.cancel')}
            </button>
            <button
              className="inline-flex justify-center flex-shrink-0 px-4 py-2 text-sm font-medium text-red-800 bg-red-300 border border-transparent rounded-md shadow-sm hover:text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={() => {
                toast.dismiss(t.id)
                deleteCustomer({
                  organizationId: currentOrganizationId,
                  id: customerId,
                })
                lastAction.current = () => {
                  deleteCustomer({
                    organizationId: currentOrganizationId,
                    id: customerId,
                  })
                }
              }}>
              {t('customerModal.deleteCustomer.confirm')}
            </button>
          </div>
        </span>
      ),
      {
        duration: 10000,
      },
    )
  }

  useEffect(() => {
    if (hasDeletedCustomer) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDeletedCustomer])

  const onNewUpdate = (kind) => {
    createInteraction({
      customerId,
      organizationId: currentOrganizationId,
      data: {
        date: new Date(),
        kind,
      },
    })
    lastAction.current = () => {
      createInteraction({
        customerId,
        organizationId: currentOrganizationId,
        data: {
          date: new Date(),
          kind,
        },
      })
    }
  }

  useEffect(() => {
    if (!isDoneCreating) {
      return
    }
    setShouldStartEditing(true)
  }, [isDoneCreating])

  const onUpdateChanged = (update) => {
    updateInteraction({
      id: update.id,
      customerId: customerId,
      organizationId: currentOrganizationId,
      data: update,
    })
    lastAction.current = () => {
      updateInteraction({
        id: update.id,
        customerId: customerId,
        organizationId: currentOrganizationId,
        data: update,
      })
    }
  }

  const isSavingCustomer =
    isDeletingInteraction ||
    isCreatingInteraction ||
    isUpdatingCustomer ||
    isUpdatingInteraction ||
    isDeletetingCustomer ||
    isCreatingHighlight ||
    isDeletingHighlight ||
    isUpdatingFollowUp ||
    isDeletingFollowUp ||
    isCreatingFollowUp ||
    isAddingFavorite ||
    isDeletingFavorite ||
    isAddingAssignee ||
    isRemovingAssignee

  const hasEncounteredError =
    hasErroredDeletingCustomer ||
    hasErroredCreatingInteraction ||
    hasErroredDeletingInteraction ||
    hasErroredUpdatingCustomer ||
    hasErroredUpdatingInteraction ||
    hasErroredDeletingHighlight ||
    hasErroredCreatingHighlight ||
    hasErroredCreatingFollowUp ||
    hasErroredUpdatingFollowUp ||
    hasErroredDeletingFollowUp ||
    hasErroredAddingFavorite ||
    hasErroredDeletingFavorite

  useEffect(() => {
    if (isSavingCustomer) {
      setNeedsSavedInfo(true)
    }
  }, [isSavingCustomer])

  useEffect(() => {
    if (isSavingCustomer) {
      return
    }

    if (!needsSavedInfo) {
      return
    }

    const timeout = setTimeout(
      () => setNeedsSavedInfo(false),
      hasEncounteredError ? 10000 : 2000,
    )

    return () => {
      clearTimeout(timeout)
    }
  }, [isSavingCustomer, needsSavedInfo, hasEncounteredError])

  useEffect(() => {
    if (!isUpdatingInteraction || !editableInteraction) {
      return
    }
    const id = editableInteraction.id
    setEditableInteraction(null)
    const timeout = setTimeout(() => {
      document
        .getElementById(`interaction-${id}`)
        .scrollIntoView({ behavior: 'smooth' })
    }, 1)

    return () => {
      clearTimeout(timeout)
    }
  }, [isUpdatingInteraction, editableInteraction])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [customer])

  const retryLastAction = () => {
    if (!lastAction.current) {
      return
    }
    lastAction.current()
  }

  const modalContent = () => {
    if (!customer && customerId) {
      return (
        <div className="flex h-[75vh] items-center justify-center">
          <Spinner width={48} height={48} className="text-indigo-500" />
        </div>
      )
    }

    return (
      <div className="grid h-full grid-cols-1 px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4 sm:grid-cols-12">
        <div className="flex flex-col items-center sm:col-span-5 sm:px-2">
          <PersonalityHeader
            customer={customer}
            onUpdatePersonality={(personality) =>
              updatePersonality(personality.id)
            }
            onAddToFavorites={onAddFavorite}
            onRemoveFromFavorites={onDeleteFavorite}
          />
          <div className="w-full mt-4 mb-2 text-center 4">
            <AutosizeTextarea
              className="w-full text-2xl font-bold leading-6 text-center text-gray-700 rounded-md resize-none focus:ring-2 focus:ring-indigo-500 focus:outline-none"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              onBlur={updateCustomerName}
              maxiumRows={2}
              fontSizeInRem={1.5}
            />
          </div>
          <div className="flex w-full">
            <textarea
              placeholder={t('customerModal.infoPlaceholder')}
              rows={isSmallDevice ? 2 : 4}
              onChange={infoChanged}
              value={customerInfo}
              onBlur={updateCustomerInfo}
              className="w-full px-2 py-2 text-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"></textarea>
          </div>
          <div className="flex flex-col w-full py-4 space-y-1">
            <div className="grid grid-cols-2 rounded-lg overflow-hidden border border-gray-200">
              <Tabs
                customer={customer}
                currentTab={currentTab}
                tabs={tabs}
                onChangeCurrentTab={setCurrentTab}
              />
            </div>
            <div className="relative">
              <div className="px-2 py-2 space-y-1 overflow-auto rounded-lg h-36 bg-zinc-50 sm:h-[40vh]">
                <TabContent
                  isLoadingCustomer={isLoadingCustomer}
                  user={user}
                  customer={customer}
                  currentTab={currentTab}
                  onShowCalendar={(followUp) => {
                    setEditableFollowUp(followUp)
                  }}
                  onUpdateFollowUp={({ id, user, change }) => {
                    updateFollowUp({
                      id,
                      organizationId: currentOrganizationId,
                      change,
                      user,
                      customerId,
                    })
                    lastAction.current = () => {
                      updateFollowUp({
                        id,
                        organizationId: currentOrganizationId,
                        change,
                        user,
                        customerId,
                      })
                    }
                  }}
                  onDeleteFollowUp={({ id }) => {
                    deleteFollowUp({
                      id,
                      customerId,
                      organizationId: currentOrganizationId,
                    })
                    lastAction.current = () => {
                      deleteFollowUp({
                        id,
                        customerId,
                        organizationId: currentOrganizationId,
                      })
                    }
                  }}
                  onCreateFollowUp={() => {
                    createFollowUp({
                      organizationId: currentOrganizationId,
                      customerId,
                      data: {
                        date: dateString(new Date()),
                        priority: 'medium',
                      },
                    })
                    lastAction.current = () => {
                      createFollowUp({
                        organizationId: currentOrganizationId,
                        customerId,
                        data: {
                          date: dateString(new Date()),
                          priority: 'medium',
                        },
                      })
                    }
                  }}
                  onDone={onHighlightDone}
                />
              </div>
              {editableFollowUp && (
                <div
                  className="absolute top-0 z-50 flex flex-col items-center px-8 py-4 bg-white border border-gray-300 rounded-md left-1/4"
                  onMouseLeave={() => setEditableFollowUp(null)}>
                  <Calendar
                    startDate={parse(editableFollowUp.date)}
                    onDateSelected={(date) => {
                      updateFollowUp({
                        id: editableFollowUp.id,
                        organizationId: currentOrganizationId,
                        change: { date: dateString(date) },
                        customerId,
                        currentDate: editableFollowUp.date,
                      })
                      setEditableFollowUp(null)
                      lastAction.current = () => {
                        updateFollowUp({
                          id: editableFollowUp.id,
                          organizationId: currentOrganizationId,
                          change: { date },
                          customerId,
                          currentDate: editableFollowUp.date,
                        })
                      }
                    }}
                  />
                  <button
                    className="mt-2 text-sm text-gray-400 transition hover:text-gray-800"
                    onClick={() => setEditableFollowUp(null)}>
                    {t('calendar.keepDate')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full bg-white sm:col-span-7">
          <div className="relative flex min-h-full">
            {editableInteraction && (
              <div
                className="absolute z-50 flex flex-col items-center justify-center px-4 py-4 bg-white border border-gray-300 rounded-md -top-6 left-1 right-1 sm:top-8 sm:bottom-8 lg:top-16 lg:bottom-16 lg:right-0 lg:left-1/2"
                onMouseLeave={() => setEditableInteraction(null)}>
                <Calendar
                  startDate={parse(
                    editableInteraction.date || editableInteraction.created_at,
                  )}
                  onDateSelected={(date) => {
                    onUpdateChanged({
                      ...editableInteraction,
                      date: dateString(date),
                    })
                  }}
                />
                <button
                  className="mt-2 text-sm text-gray-400 transition hover:text-gray-800"
                  onClick={() => setEditableInteraction(null)}>
                  {t('calendar.keepDate')}
                </button>
              </div>
            )}
            <div
              className="flex flex-col w-full h-64 mt-2 space-y-2 overflow-auto sm:pl-8 sm:h-full"
              style={{ maxHeight: '75vh' }}>
              <InteractionMenu
                onPicked={onNewUpdate}
                customerName={customer?.name}
                showHint={customer?.interactions?.length === 0}
              />
              {customer?.interactions
                .sort((a, b) => {
                  if (a.date === b.date) {
                    return (
                      parse(b.created_at).getTime() -
                      parse(a.created_at).getTime()
                    )
                  }

                  return (
                    parse(b.date || b.created_at).getTime() -
                    parse(a.date || a.created_at).getTime()
                  )
                })
                .map((interaction, index) => (
                  <Interaction
                    key={interaction.id}
                    interaction={interaction}
                    isFromCurrentUser={interaction?.user?.id === user?.id}
                    highlights={customer.highlights.filter(
                      (h) => h.interaction_id === interaction.id,
                    )}
                    onEditInteractionDate={setEditableInteraction}
                    colorsForHighlight={(highlight) =>
                      tabs.filter((t) => t.type === highlight.type).shift()
                    }
                    shouldStartEditing={index === 0 && shouldStartEditing}
                    onStartedEditing={() => setShouldStartEditing(false)}
                    tab={tabs[currentTab]}
                    last={index === customer.interactions.length - 1}
                    onChanged={(changedUpdate) =>
                      onUpdateChanged(changedUpdate, index)
                    }
                    onCreateHighlight={(text, kind) => {
                      lastAction.current = () => {
                        createHighlight({
                          organizationId: currentOrganizationId,
                          customerId,
                          data: {
                            title: text,
                            kind,
                            interaction_type: interaction.type,
                            interaction_id: interaction.id,
                          },
                        })
                      }

                      return createHighlight({
                        organizationId: currentOrganizationId,
                        customerId,
                        data: {
                          title: text,
                          kind,
                          interaction_type: interaction.type,
                          interaction_id: interaction.id,
                        },
                      })
                    }}
                    onDeleteHighlight={(id) => {
                      deleteHighlight({
                        id,
                        customerId,
                        organizationId: currentOrganizationId,
                      })
                    }}
                    onDelete={() => onDeleteUpdate(interaction)}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          initialFocus={successButtonRef}
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={onClose}>
          <div className="flex items-end justify-center w-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block w-full overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-7xl">
                <Toaster position="bottom-center" />
                {modalContent()}

                <div className="flex items-center justify-between px-4 py-3 text-right bg-gray-50 sm:px-6">
                  <div className="flex items-center">
                    {!!customerId && (
                      <Assignees
                        assignees={customer?.assignees || []}
                        customerName={customer?.name}
                        user={user}
                        organization={organization}
                        onAdd={(user) =>
                          addAssignee({
                            customerId,
                            organizationId: currentOrganizationId,
                            user,
                          })
                        }
                        onRemove={(userId) =>
                          removeAssignee({
                            customerId,
                            organizationId: currentOrganizationId,
                            userId,
                          })
                        }
                      />
                    )}
                  </div>
                  <div>
                    <button
                      onClick={onDeleteCustomer}
                      className="mr-4 text-gray-400 hover:text-red-800">
                      {t('customerModal.deleteButton')}
                    </button>
                    <CTAButton
                      ref={successButtonRef}
                      onClick={() => {
                        if (hasEncounteredError) {
                          retryLastAction()
                        } else {
                          onClose()
                        }
                      }}
                      loading={isSavingCustomer}
                      success={needsSavedInfo && !hasEncounteredError}
                      error={needsSavedInfo && hasEncounteredError}
                      progressText={t('customerModal.saving.inProgress')}
                      successText={t('customerModal.saving.done')}
                      errorText={t('customerModal.saving.tryAgainButton')}
                      className="inline-flex justify-center flex-shrink-0 px-4 py-2 text-sm font-medium border border-transparent rounded-md shadow-sm"
                      text={t('customerModal.okButton')}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {['indicators', 'recommendations'].map((tooltip) => (
        <ReactTooltip
          key={tooltip}
          id={tooltip}
          place="bottom"
          className="z-20 drop-shadow"
          effect="solid"
          textColor="#27272a"
          backgroundColor="#f4f4f5"
        />
      ))}
    </>
  )
}

CustomerModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  organization: PropTypes.any,
  currentOrganizationId: PropTypes.string,
  customerId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
