/* eslint-disable no-undef */
import React, { Fragment, useRef, useState, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { useKey } from 'react-use'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import ReactTooltip from 'react-tooltip'
import useCustomer from '../../hooks/useCustomer'
import useSearch from '../../hooks/useSearch'
import useTranslation from '../../hooks/useTranslation'
import { useApp } from '../../hooks/useAppContext'
import CTAButton from '../CTAButton'
import PersonalityPicker from './personalityPicker'

export default function CreateCustomerModal({
  currentOrganizationId,
  user,
  open,
  onClose,
}) {
  const { useCreateCustomer } = useCustomer()
  const {
    createCustomer,
    data: customerData,
    isLoading,
    isError,
  } = useCreateCustomer()
  const [customer, setCustomer] = useState({
    name: '',
    personality: 'unknown',
  })
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const nameInputRef = useRef()
  const submitButtonRef = useRef()
  const { hits } = useSearch({
    value: customer.name,
    indexName: `Customer_${process.env.NODE_ENV}`,
    searchApiKey: user?.search_api_key,
    currentOrganizationId,
  })
  const t = useTranslation()
  const { router } = useApp()

  const updateName = (e) => {
    setCustomer((old) => {
      return {
        ...old,
        name: e.target.value,
      }
    })
  }

  const updatePersonality = (personality) => {
    setCustomer((old) => {
      return {
        ...old,
        personality: personality.id,
      }
    })
  }

  const buttonEnabled = () => customer.name

  const submitForm = async () => {
    if (!nameInputRef.current?.reportValidity()) {
      return
    }
    createCustomer({
      organizationId: currentOrganizationId,
      data: {
        ...customer,
        name: customer.name.trim(),
      },
    })
  }

  useKey('Enter', () => {
    if (!open) {
      return
    }
    submitForm()
  })

  useEffect(() => {
    if (!isError) {
      return
    }

    if (isError) {
      setError(true)
      const timeout = setTimeout(() => setError(false), 2000)

      return () => {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  useEffect(() => {
    if (!open) {
      ReactTooltip.hide()
    }
  }, [open])

  useEffect(() => {
    if (!customerData || !customerData.id) {
      return
    }
    setCustomer({
      name: '',
      personality: 'unknown',
    })
    setSuccess(true)
    setTimeout(() => {
      onClose()
      setTimeout(() => {
        router.push(`?c=${customerData.id}`, `/c/${customerData.id}`, {
          shallow: true,
        })
        setSuccess(false)
      }, 500)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData])

  const numberOfSimilarEntries = (text) => {
    return similarEntries(text).length
  }

  const similarEntries = (text) => {
    if (!text) {
      return []
    }

    return (hits || []).filter(({ name }) =>
      name.toLowerCase().includes(text.toLowerCase().trim()),
    )
  }

  useEffect(() => {
    ReactTooltip.hide()
    ReactTooltip.rebuild()
  }, [customer.name])

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={nameInputRef}
          onClose={onClose}>
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      submitForm()

                      return false
                    }}>
                    <div className="overflow-hidden shadow sm:rounded-md">
                      <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-1 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700">
                              Name
                            </label>
                            <input
                              type="text"
                              ref={nameInputRef}
                              name="first-name"
                              id="first-name"
                              value={customer.name}
                              onChange={updateName}
                              required
                              autoComplete="given-name"
                              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm form-input focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm focus:outline-none"
                            />
                            <div
                              className={cx(
                                'flex space-x-1 items-center w-fit mt-2 text-xs text-gray-400',
                                {
                                  'opacity-0':
                                    numberOfSimilarEntries(customer.name) === 0,
                                  'opacity-100':
                                    numberOfSimilarEntries(customer.name) > 0,
                                },
                              )}>
                              <ExclamationCircleIcon className="w-4 h-4" />
                              <p
                                data-html={true}
                                data-tip={ReactDOMServer.renderToString(
                                  similarEntries(customer.name).map(
                                    ({ name }, index) => (
                                      <li key={`${name}-${index}`}>{name}</li>
                                    ),
                                  ),
                                )}
                                dangerouslySetInnerHTML={{
                                  __html: t('createCustomer.similarCustomers', {
                                    count: numberOfSimilarEntries(
                                      customer.name,
                                    ),
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-center w-full">
                        <p className="mt-8 text-sm text-gray-700">
                          {t('createCustomer.is')}
                        </p>
                      </div>
                      <div className="flex w-full px-6 mt-4 mb-12 gap-x-2">
                        <PersonalityPicker
                          onPersonalitySelected={updatePersonality}
                        />
                      </div>
                      <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                        <span
                          onClick={onClose}
                          className="self-start mr-4 text-gray-400 cursor-pointer hover:text-red-800">
                          {t('createCustomer.cancel')}
                        </span>
                        <CTAButton
                          ref={submitButtonRef}
                          onClick={submitForm}
                          disabled={!buttonEnabled()}
                          loading={isLoading}
                          error={error}
                          success={success}
                          className="inline-flex justify-center flex-shrink-0 px-4 py-2 text-sm font-medium text-white"
                          text={t('createCustomer.confirm')}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ReactTooltip
        place="bottom"
        className="z-20 drop-shadow"
        effect="solid"
        textColor="#27272a"
        backgroundColor="#f4f4f5"
      />
    </>
  )
}

CreateCustomerModal.propTypes = {
  currentOrganizationId: PropTypes.string,
  open: PropTypes.bool,
  user: PropTypes.shape({
    search_api_key: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func,
}
