import React from 'react'
import { EmojiHappyIcon, EmojiSadIcon } from '@heroicons/react/outline'
import CTAButton from '../../components/CTAButton'
import useTranslation from '../useTranslation'

export default function useTabs({ onScheduleFollowUp } = {}) {
  const t = useTranslation()

  return [
    {
      name: t('customerModal.tabs.needsHelp.text'),
      bgColor: 'bg-red-300',
      textColor: 'text-red-800',
      accentColor: 'text-red-100',
      badgeTextColor: 'text-red-300',
      badgeBgColor: 'bg-red-800',
      emptyStateText: t('customerModal.tabs.needsHelp.emptyState'),
      type: 'issue',
      emojiComponent: <EmojiHappyIcon className="w-5 h-5 ml-2 text-gray-400" />,
    },
    {
      name: t('customerModal.tabs.favors.text'),
      bgColor: 'bg-green-300',
      textColor: 'text-green-800',
      accentColor: 'text-green-100',
      badgeTextColor: 'text-green-300',
      badgeBgColor: 'bg-green-800',
      emptyStateText: t('customerModal.tabs.favors.emptyState'),
      type: 'favor',
      emojiComponent: <EmojiSadIcon className="w-5 h-5 ml-2 text-gray-400" />,
    },
    {
      name: t('customerModal.tabs.infos.text'),
      bgColor: 'bg-cyan-300',
      textColor: 'text-cyan-800',
      accentColor: 'text-cyan-100',
      badgeTextColor: 'text-cyan-300',
      badgeBgColor: 'bg-cyan-800',
      emptyStateText: t('customerModal.tabs.infos.emptyState'),
      type: 'info',
      emojiComponent: <EmojiSadIcon className="w-5 h-5 ml-2 text-gray-400" />,
    },
    {
      name: t('customerModal.tabs.followUps.text'),
      bgColor: 'bg-blue-300',
      textColor: 'text-blue-800',
      accentColor: 'text-blue-100',
      hoverBgColor: 'hover:bg-blue-300',
      badgeTextColor: 'text-blue-300',
      badgeBgColor: 'bg-blue-800',
      emptyStateText: t('customerModal.tabs.followUps.emptyState'),
      type: 'followUp',
      emojiComponent: <EmojiSadIcon className="w-5 h-5 ml-2 text-gray-400" />,
      buttonComponent: (
        <CTAButton
          className="text-sm"
          text={t('customerModal.tabs.followUps.emptyStateButton')}
          onClick={onScheduleFollowUp}
        />
      ),
    },
  ]
}
