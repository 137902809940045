import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TrashIcon } from '@heroicons/react/outline'
import { useDebouncedValue } from '@mantine/hooks'
import useInteractionTypes from '../../hooks/useInteractionTypes'
import useDate from '../../hooks/useDate'
import useTranslation from '../../hooks/useTranslation'
import InteractionMenu from './interactionMenu'
import InteractionTextfield from './interactionTextfield'

export default function Interaction({
  interaction,
  isFromCurrentUser,
  highlights,
  shouldStartEditing,
  onStartedEditing,
  last,
  onChanged,
  onEditInteractionDate,
  onCreateHighlight,
  onDeleteHighlight,
  onDelete,
}) {
  const [hoverEditButton, setHoverEditButton] = useState(false)
  const [text, setText] = useState(interaction.text || '')
  const [formattedText, setFormattedText] = useState(
    interaction.formatted_text || '',
  )
  const { icon } = useInteractionTypes({ interaction })
  const { parse } = useDate()
  const [debounced] = useDebouncedValue(text, 2000)
  const t = useTranslation()

  const onTextChanged = (value) => {
    setText(
      value
        .map((p) => p.children.map((c) => c.text).join(''))
        .join('\n')
        .trim(),
    )
    setFormattedText(JSON.stringify(value))
  }

  const saveUpdate = () => {
    if (interaction.formatted_text === formattedText) {
      return
    }
    onChanged({ ...interaction, text, formatted_text: formattedText })
  }

  const date = interaction.date || interaction.created_at

  useEffect(() => {
    setHoverEditButton(false)
  }, [interaction.id])

  useEffect(() => {
    saveUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced])

  return (
    <div className="flex flex-col" id={`interaction-${interaction.id}`}>
      <div className="flex items-start pt-1">
        <div className="relative flex flex-col">
          <button
            onMouseOver={() => {
              setHoverEditButton(true)
            }}
            className="z-10 flex flex-col items-center justify-center w-8 h-8 transition bg-gray-300 rounded-full">
            {icon(interaction.type)}
          </button>
          {hoverEditButton && (
            <div className="absolute z-10 -top-1">
              <InteractionMenu
                isNew={false}
                open={hoverEditButton}
                initialButtonType={interaction.type}
                onDelete={() => {
                  onDelete()
                  setHoverEditButton(false)
                }}
                onPicked={(type) => {
                  onChanged({ ...interaction, kind: type, type })
                  setHoverEditButton(false)
                }}
              />
            </div>
          )}
          <div
            className={`absolute w-0.5 ${
              last ? 'h-full' : 'h-48'
            } left-[15px] bg-gray-100 top-8`}
          />
          <p className="z-10 text-center">
            <span className="text-sm text-gray-400 bg-gray-50">
              {' '}
              {isFromCurrentUser
                ? t('customerModal.interaction.you')
                : `${interaction.user?.short_name || ''}`}
            </span>
          </p>
        </div>
        <div className="w-full -mt-1 overflow-visible ">
          <InteractionTextfield
            highlights={highlights}
            onBlur={() => {
              saveUpdate()
            }}
            onChange={onTextChanged}
            onCreateHighlight={onCreateHighlight}
            onDeleteHighlight={onDeleteHighlight}
            initialValue={formattedText}
            shouldStartEditing={shouldStartEditing}
            onStartedEditing={onStartedEditing}>
            <div className="absolute flex items-center justify-end right-3 top-1 w-fit">
              {date && (
                <button
                  onClick={() => onEditInteractionDate(interaction)}
                  className="mr-1 text-xs text-gray-300 hover:text-gray-800">
                  {`${parse(date).toLocaleDateString()}`}
                </button>
              )}
              <TrashIcon
                className="w-5 h-5 text-gray-200 hover:text-red-800"
                onClick={onDelete}
              />
            </div>
          </InteractionTextfield>
        </div>
      </div>
    </div>
  )
}

Interaction.propTypes = {
  interaction: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    formatted_text: PropTypes.string,
    type: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      short_name: PropTypes.string,
    }),
    date: PropTypes.any,
    created_at: PropTypes.any,
  }),
  isFromCurrentUser: PropTypes.bool,
  highlights: PropTypes.array,
  tab: PropTypes.shape({
    textColor: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
  }),
  last: PropTypes.bool.isRequired,
  shouldStartEditing: PropTypes.bool.isRequired,
  onEditInteractionDate: PropTypes.func.isRequired,
  onStartedEditing: PropTypes.func.isRequired,
  onChanged: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCreateHighlight: PropTypes.func.isRequired,
  onDeleteHighlight: PropTypes.func,
  colorsForHighlight: PropTypes.func.isRequired,
}
