import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { useIntersection, useDebouncedValue } from '@mantine/hooks'
import usePersonalities from '../../hooks/usePersonalities'
import useSortTypes from '../../hooks/useSortTypes'
import useDate from '../../hooks/useDate'
import useCustomer from '../../hooks/useCustomer'
import { useApp } from '../../hooks/useAppContext'
import Spinner from '../../assets/spinner.svg'
import EmptyState from './emptyState'

function dateDiffInDaysText(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export default function All({
  currentOrganizationId,
  onCreateContact,
  containerRef,
  userId,
  listMode,
}) {
  const { useGetInfiniteCustomers } = useCustomer()
  const { SORT_TYPES } = useSortTypes()
  const [domReady, setDomReady] = useState(false)
  const [sort, setSort] = useState(SORT_TYPES)
  const { customerPages, fetchNextPage, isFetchingNextPage } =
    useGetInfiniteCustomers({
      organizationId: currentOrganizationId,
      userId,
      view: 'list',
      sort: sort.find((s) => s.isActive).id,
      direction: sort.find((s) => s.isActive).direction,
      listMode,
    })
  const [observableRef, observedEntry] = useIntersection()
  const [loading] = useDebouncedValue(isFetchingNextPage, 1000)
  const [dragging, setDragging] = useState(false)
  const onDragEnter = (e, item) => {
    setDragging(true)
    e.dataTransfer.setDragImage(document.getElementById(item.customer.id), 0, 0)
    e.dataTransfer.setData('text/plain', JSON.stringify(item))
  }
  const { getById } = usePersonalities()
  const { parse } = useDate()
  const { router } = useApp()

  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const dayText = (difference) => {
    if (difference === 0) {
      return t('all.list.today')
    }

    if (difference < 0) {
      return t('all.list.timeAgoText', {
        days: Math.abs(difference),
        count: Math.abs(difference),
      })
    }

    return t('all.list.inTheFutureText', {
      days: Math.abs(difference),
      count: Math.abs(difference),
    })
  }

  useEffect(() => {
    if (observedEntry?.isIntersecting) {
      fetchNextPage()
    }
  }, [observedEntry, fetchNextPage])

  useEffect(() => {
    setDomReady(true)
  }, [])

  const setSortValue = (sortValue) => {
    const activeSort = sort.find((s) => s.isActive === true)
    const activeSortIndex = sort.findIndex((s) => s.isActive === true)

    if (activeSort.id === sortValue) {
      const newActiveSort = {
        ...activeSort,
        direction: activeSort.direction === 'asc' ? 'desc' : 'asc',
      }
      setSort((old) => {
        return [
          ...old.slice(0, activeSortIndex),
          newActiveSort,
          ...old.slice(activeSortIndex + 1),
        ]
      })
    } else {
      const newActiveSortIndex = sort.findIndex((s) => s.id === sortValue)
      const newActiveSort = {
        id: sortValue,
        direction: sort[newActiveSortIndex].direction,
        isActive: true,
      }
      setSort((old) => {
        return [
          ...old.slice(0, newActiveSortIndex).map((o) => {
            return {
              ...o,
              isActive: false,
            }
          }),
          newActiveSort,
          ...old.slice(newActiveSortIndex + 1).map((o) => {
            return {
              ...o,
              isActive: false,
            }
          }),
        ]
      })
    }
  }

  const activeSort = sort.find((s) => s.isActive === true).id
  const direction = (id) => sort.find((s) => s.id === id).direction

  const LoadingOverlay = () => (
    <div className="absolute flex items-center justify-center w-full h-full">
      <Spinner className="z-20 text-indigo-500" width={50} height={50} />
    </div>
  )

  if (customerPages && customerPages[0]?.customer.length === 0) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <EmptyState tab="list" onClick={onCreateContact} />
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table
              className={cx('min-w-full divide-y divide-gray-300', {
                'blur-sm': loading,
              })}>
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    onClick={() => setSortValue('name')}>
                    <p className="inline-flex group">
                      {t('all.sort.name')}
                      <span
                        className={cx(
                          'flex-none ml-2 text-gray-900 bg-gray-200 rounded group-hover:visible group-focus:visible',
                          {
                            'invisible text-gray-400': activeSort !== 'name',
                            'text-gray-900 bg-gray-200 group-hover:bg-gray-300':
                              activeSort === 'name',
                          },
                        )}>
                        {direction('name') === 'asc' ? (
                          <ChevronUpIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDownIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    onClick={() => setSortValue('lastUpdate')}>
                    <p className="inline-flex group">
                      {t('all.sort.lastUpdate')}
                      <span
                        className={cx(
                          'flex-none ml-2 rounded group-hover:visible group-focus:visible',
                          {
                            'invisible text-gray-400':
                              activeSort !== 'lastUpdate',
                            'text-gray-900 bg-gray-200 group-hover:bg-gray-300':
                              activeSort === 'lastUpdate',
                          },
                        )}>
                        {direction('lastUpdate') === 'asc' ? (
                          <ChevronUpIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDownIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    onClick={() => setSortValue('nextFollowUp')}>
                    <p className="inline-flex group">
                      {t('all.sort.nextFollowUp')}
                      <span
                        className={cx(
                          'flex-none ml-2 text-gray-900 rounded group-hover:visible group-focus:visible',
                          {
                            'invisible text-gray-400':
                              activeSort !== 'nextFollowUp',
                            'text-gray-900 bg-gray-200 group-hover:bg-gray-300':
                              activeSort === 'nextFollowUp',
                          },
                        )}>
                        {direction('nextFollowUp') === 'asc' ? (
                          <ChevronUpIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDownIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {customerPages?.map((page) => (
                  <React.Fragment key={page.nextId}>
                    {page?.customer
                      ?.map((c) => ({
                        id: c.id,
                        customer: c,
                        last_interaction: c.last_interaction,
                        next_follow_up: c.next_follow_up,
                      }))
                      .map((item, index) => (
                        <tr
                          key={item.customer.id}
                          draggable
                          onDragStart={(e) => onDragEnter(e, item)}
                          onDragEnd={() => setDragging(false)}
                          onClick={() =>
                            router.push(
                              `?c=${item.customer.id}`,
                              `/c/${item.customer.id}`,
                              {
                                shallow: true,
                              },
                            )
                          }
                          className={cx('transition', {
                            'opacity-30': dragging,
                          })}>
                          {index > 0 &&
                            index % Math.ceil(page?.customer?.length / 2) ===
                              0 && <div ref={observableRef} />}
                          <td className="flex items-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div
                              id={item.customer.id}
                              className="flex items-center justify-center w-16 h-16 text-4xl text-center rounded-full bg-gray-50">
                              {getById(item.customer.personality).icon}
                            </div>
                            <p className="ml-2">{item.customer.name}</p>
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                            <span>
                              {item.last_interaction?.date ||
                              item.last_interaction?.created_at ? (
                                <>
                                  <span className="text-gray-600">
                                    {parse(
                                      item.last_interaction?.date ||
                                        item.last_interaction?.created_at,
                                    ).toLocaleDateString(undefined, {
                                      weekday: 'short',
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                    })}{' '}
                                  </span>
                                  <span className="text-gray-300">
                                    (
                                    {dayText(
                                      dateDiffInDaysText(
                                        new Date(),
                                        parse(
                                          item.last_interaction.date ||
                                            item.last_interaction?.created_at,
                                        ),
                                      ),
                                    )}
                                    )
                                  </span>
                                </>
                              ) : (
                                <span className="text-gray-300">
                                  {t('all.placeholder.lastUpdate')}
                                </span>
                              )}
                            </span>
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                            <span>
                              {item.next_follow_up ? (
                                <>
                                  <span className="text-gray-600">
                                    {parse(
                                      item.next_follow_up.date,
                                    ).toLocaleDateString(undefined, {
                                      weekday: 'short',
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                    })}{' '}
                                  </span>
                                  <span className="text-gray-300">
                                    (
                                    {dayText(
                                      dateDiffInDaysText(
                                        new Date(),
                                        parse(item.next_follow_up.date),
                                      ),
                                    )}
                                    )
                                  </span>
                                </>
                              ) : (
                                <span className="text-gray-300">
                                  {t('all.placeholder.nextFollowUp')}
                                </span>
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {domReady &&
        loading &&
        ReactDOM.createPortal(<LoadingOverlay />, containerRef.current)}
    </div>
  )
}

All.propTypes = {
  containerRef: PropTypes.object,
  currentOrganizationId: PropTypes.string.isRequired,
  onCreateContact: PropTypes.func,
  userId: PropTypes.string,
  listMode: PropTypes.string,
}
