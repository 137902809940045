import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TrashIcon } from '@heroicons/react/outline'

export default function Assignee({ shortName, zIndex, onRemove }) {
  const [hover, setHover] = useState(false)

  return (
    <button
      style={{ zIndex }}
      className="relative inline-flex items-center justify-center w-8 h-8 rounded-full bg-gray-50 ring-2 ring-gray-200 hover:ring-red-800"
      onClick={onRemove}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      {hover ? (
        <TrashIcon className="w-5 h-5 text-red-800" />
      ) : (
        <span className="text-xs font-medium leading-none text-gray-600">
          {shortName}
        </span>
      )}
    </button>
  )
}

Assignee.propTypes = {
  shortName: PropTypes.string,
  zIndex: PropTypes.number,
  onRemove: PropTypes.func,
}
