import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Range, Editor, Node, Transforms } from 'slate'
import { useSlate, ReactEditor } from 'slate-react'
import { usePopper } from 'react-popper'
import { useClickOutside } from '@mantine/hooks'
import {
  LightBulbIcon,
  LightningBoltIcon,
  SparklesIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import useTranslation from '../../hooks/useTranslation'

export default function HoveringToolbar({
  open,
  setOpen,
  deletion,
  setDeletion,
  onCreateHighlight,
  onDeleteHighlight,
}) {
  const [highlightedText, setHighlightedText] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [popperEl, setPopperEl] = useState(null)
  const editor = useSlate()
  const { selection } = editor
  const { styles, attributes } = usePopper(anchorEl, popperEl, {
    modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
  })
  const _ref = useClickOutside(() => {
    setOpen(false)
    setDeletion(false)
  })
  const t = useTranslation()

  useEffect(() => {
    _ref.current = popperEl
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperEl])

  useEffect(() => {
    if (!deletion || !hasSelection()) {
      return
    }

    const { width, height } = getBoundingClientRect()
    setAnchorEl({
      clientWidth: width,
      clientHeight: height,
      getBoundingClientRect,
    })
  }, [deletion])

  const hasSelection = () => {
    const selection = window.getSelection()

    return selection && selection.rangeCount > 0
  }

  useEffect(() => {
    const domSelection = window.getSelection()

    if (
      !selection ||
      !hasSelection() ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === '' ||
      hasAnyMark()
    ) {
      setOpen(false)
      setDeletion(false)

      return
    }

    setOpen(true)
    const { width, height } = getBoundingClientRect()
    setAnchorEl({
      clientWidth: width,
      clientHeight: height,
      getBoundingClientRect,
    })
    setHighlightedText(domSelection.toString())
  }, [selection, editor, setOpen, hasAnyMark, setDeletion])

  const hasAnyMark = useCallback(() => {
    return !![
      ...Node.nodes(editor, {
        from: selection.anchor.path,
        to: selection.focus.path,
      }),
      ...Node.nodes(editor, {
        from: selection.focus.path,
        to: selection.anchor.path,
      }),
    ]
      .flat()
      .find((el) => el.favor || el.issue || el.info)
  }, [editor, selection])

  const getBoundingClientRect = () => {
    const rects = window.getSelection().getRangeAt(0).getClientRects()

    return rects.item(rects.length - 1)
  }

  const isMarkActive = useCallback(
    (type) => {
      const marks = Editor.marks(editor)

      return marks ? marks[type] === true : false
    },
    [editor],
  )

  const handleClick = async (type) => {
    if (
      isMarkActive('issue') ||
      isMarkActive('favor') ||
      isMarkActive('info')
    ) {
      Editor.removeMark(editor, 'issue')
      Editor.removeMark(editor, 'favor')
      Editor.removeMark(editor, 'info')
    }
    const highlight = await onCreateHighlight(highlightedText, type)

    Editor.addMark(editor, type, true)
    Editor.addMark(editor, 'highlightId', highlight.id)

    setOpen(false)
    ReactEditor.deselect(editor)
    ReactEditor.focus(editor)
  }

  const deleteMarking = async () => {
    // figure out the marked text and find the corresponding highlight
    // then remove it from the editor
    // and make the backend call to remove the hightlight
    const selectedNode = Editor.first(editor, selection)
      .flat()
      .find((el) => el.favor || el.issue || el.info)
    Transforms.unsetNodes(editor, ['favor', 'issue', 'info'], {
      at: [],
      match: (node) => node.text === selectedNode.text,
    })
    const highlightId = selectedNode.highlightId
    ReactEditor.deselect(editor)
    ReactEditor.focus(editor)
    await onDeleteHighlight(highlightId)
    setOpen(false)
    setDeletion(false)
  }

  return (
    <>
      {open && (
        <div
          ref={setPopperEl}
          style={styles.popper}
          className="z-20 flex drop-shadow"
          {...attributes.popper}>
          {deletion ? (
            <button
              className="flex items-center px-10 py-2 text-red-800 bg-white border border-gray-200 rounded-full hover:bg-red-200 hover:border-red-800"
              onClick={deleteMarking}>
              <TrashIcon className="w-5 h-5" />
            </button>
          ) : (
            <>
              <button
                className="flex items-center px-4 py-2 text-red-800 bg-white border border-gray-200 rounded-l-full border-r-none hover:bg-red-200 hover:border-red-800"
                onClick={() => handleClick('issue')}>
                <LightningBoltIcon className="w-5 h-5 mr-2" />
                {t('customerModal.interaction.menu.needsHelp')}
              </button>
              <button
                className="flex items-center px-4 py-2 text-green-800 bg-white border border-gray-200 hover:bg-green-200 hover:border-green-800"
                onClick={() => handleClick('favor')}>
                <SparklesIcon className="w-5 h-5 mr-2" />
                {t('customerModal.interaction.menu.favor')}
              </button>
              <button
                className="flex items-center px-4 py-2 text-cyan-800 bg-white border border-gray-200 rounded-r-full hover:bg-cyan-200 hover:border-cyan-800"
                onClick={() => handleClick('info')}>
                <LightBulbIcon className="w-5 h-5 mr-2" />
                {t('customerModal.interaction.menu.info')}
              </button>
            </>
          )}
        </div>
      )}
    </>
  )
}

HoveringToolbar.propTypes = {
  deletion: PropTypes.bool,
  open: PropTypes.bool,
  onCreateHighlight: PropTypes.func.isRequired,
  onDeleteHighlight: PropTypes.func,
  setDeletion: PropTypes.func,
  setOpen: PropTypes.func.isRequired,
}
