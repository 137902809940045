import { useEffect } from 'react'
import { useTggl, useFlag as _useFlag } from 'react-tggl-client'
import { useApp } from '../useAppContext'

export default function useFlag(name) {
  const { user } = useApp()
  const { setContext } = useTggl()

  useEffect(() => {
    if (!user) {
      return
    }

    setContext({
      userId: user?.id,
      email: user?.email,
    })
  }, [user])

  const value = _useFlag(name)

  return value
}
