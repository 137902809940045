import React, { useState, useEffect } from 'react'
import { PlusIcon } from '@heroicons/react/solid'
import CustomerModal from '../components/CustomerModal'
import CreateCustomerModal from '../components/CreateCustomerModal'
import DayFollowUps from '../components/DayFollowUps'
import useUser from '../hooks/useUser'
import { useApp } from '../hooks/useAppContext'
import HelpingTodayButton from '../components/HelpingTodayButton'
import EverybodyButton from '../components/EverybodyButton'
import useTranslation from '../hooks/useTranslation'

export default function Home() {
  const t = useTranslation()
  const { router } = useApp()
  const customerId = router.query.c
  const { useGetMe, useGetOrganization, useGetCurrentOrganizationId } =
    useUser()
  const { user } = useGetMe()
  const { currentOrganizationId } = useGetCurrentOrganizationId({ user })
  const [followUpDate, setFollowUpDate] = useState(new Date())
  const [currentFollowUpUser, setCurrentFollowUpUser] = useState()
  const [currentListUser, setCurrentListUser] = useState({
    id: 'all',
    name: t('followUps.list.all'),
  })
  const [tab, setTab] = useState('day')
  const [listMode, setListMode] = useState('everybody')

  const openNewCustomerModal = () => {
    router.push('?new=true', '/c/new', { shallow: true })
  }

  const { organization } = useGetOrganization({
    organizationId: currentOrganizationId,
  })

  useEffect(() => {
    setCurrentFollowUpUser(user)
  }, [user])

  return (
    <main
      className="w-full px-2 py-8 lg:px-16 bg-zinc-100"
      style={{ height: 'calc(100vh - 64px)' }}>
      <CustomerModal
        organization={organization}
        currentOrganizationId={currentOrganizationId}
        user={user}
        customerId={customerId}
        open={!!customerId}
        onClose={() => router.push('/')}
      />
      <CreateCustomerModal
        currentOrganizationId={currentOrganizationId}
        user={user}
        open={!!router.query.new}
        onClose={() => router.push('/')}
      />
      <div className="px-2 py-4 bg-white md:px-8 rounded-3xl">
        <div className="flex flex-col items-center pb-8 space-y-3 border-b border-gray-100 md:space-x-8 md:flex-row md:space-y-0 md:pb-4">
          <HelpingTodayButton
            tab={tab}
            user={user}
            organization={organization}
            currentFollowUpUser={currentFollowUpUser}
            followUpDate={followUpDate}
            onTabChanged={setTab}
            selectUser={(userId) =>
              setCurrentFollowUpUser(
                organization?.users.find((u) => u.id === userId),
              )
            }
          />
          <EverybodyButton
            tab={tab}
            onTabChanged={setTab}
            user={user}
            currentListUser={currentListUser}
            organization={organization}
            onModeChanged={(mode) => setListMode(mode.id)}
            onUserSelected={setCurrentListUser}
          />
          <div className="flex flex-col flex-grow justify-items-end">
            <button
              type="button"
              onClick={openNewCustomerModal}
              className="inline-flex items-center self-end px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-indigo-600 rounded-md shadow-sm hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
              {t('followUps.newCustomer')}
            </button>
          </div>
        </div>
        <DayFollowUps
          currentOrganizationId={currentOrganizationId}
          user={user}
          modalOpen={!!customerId}
          tab={tab}
          currentDate={followUpDate}
          followUpUser={currentFollowUpUser}
          currentListUserId={currentListUser?.id}
          listMode={listMode}
          onCreateFollowUp={() => setTab('list')}
          onCreateContact={openNewCustomerModal}
          onDateChanged={(date) => setFollowUpDate(date)}
        />
      </div>
    </main>
  )
}

Home.propTypes = {}
