import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createEditor, Transforms, Editor } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import useTranslation from '../../hooks/useTranslation'
import useFlag from '../../hooks/useFlag'
import HoveringToolbar from './hoveringToolbar'

export default function InteractionTextfield({
  onBlur,
  onChange,
  onCreateHighlight,
  onDeleteHighlight,
  highlights,
  initialValue,
  shouldStartEditing,
  onStartedEditing,
  children,
}) {
  const t = useTranslation()
  const { active } = useFlag('pastableUrls')
  const withHeading = (editor) => {
    const { normalizeNode } = editor

    editor.normalizeNode = (entry) => {
      const [node, path] = entry

      if (path.length === 1 && path[0] === 0 && node.type !== 'heading') {
        Transforms.setNodes(editor, { type: 'heading' }, { at: path })
      }

      if (path.length === 1 && path[0] !== 0 && node.type !== 'paragraph') {
        Transforms.setNodes(editor, { type: 'paragraph' }, { at: path })
      }

      normalizeNode(entry)
    }

    return editor
  }
  const [toolbarOpen, setToolbarOpen] = useState()
  const [isDeletion, setIsDeletion] = useState(false)
  const editor = useMemo(() => withHeading(withReact(createEditor())), [])
  const highlightsCount = useRef(highlights.length)

  const cleanValue = (value) => {
    const val = JSON.parse(value).filter((value) => {
      if (value === null || value.children.includes(null)) {
        return false
      }

      return true
    })

    return val.length > 0
      ? val
      : [
          {
            type: 'heading',
            children: [
              {
                text: '',
              },
            ],
          },
        ]
  }

  // filter out null values for children to allow them to use it for now.
  // then fix it on backend
  const _initialValue = useMemo(
    () =>
      initialValue
        ? cleanValue(initialValue)
        : [
            {
              type: 'heading',
              children: [
                {
                  text: '',
                },
              ],
            },
          ],
    [initialValue],
  )

  const isURL = (str) => {
    try {
      const _url = new URL(str)

      return _url
    } catch (_) {
      return false
    }
  }

  const renderElement = useCallback(({ element, attributes, children }) => {
    switch (element.type) {
      case 'heading':
        return (
          <h1 className="text-base text-gray-800 lg:text-lg" {...attributes}>
            {children}
          </h1>
        )
      default:
        return (
          <p className="text-sm text-gray-500" {...attributes}>
            {children}
          </p>
        )
    }
  }, [])

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    let classNames

    console.log(leaf)

    if (leaf.link || isURL(leaf.url)) {
      return (
        <span
          {...attributes}
          className="bg-gray-100 text-gray-600 px-2 hover:bg-gray-200 rounded-xl"
          onClick={() => window.open(leaf.link.url, '_blank')}>
          {children}
        </span>
      )
    }

    if (leaf.issue) {
      classNames = 'bg-red-300 text-red-800'
      attributes.onClick = () => {
        setIsDeletion(true)
        setToolbarOpen(true)
      }
    }

    if (leaf.favor) {
      classNames = 'bg-green-300 text-green-800'
      attributes.onClick = () => {
        setIsDeletion(true)
        setToolbarOpen(true)
      }
    }

    if (leaf.info) {
      classNames = 'bg-cyan-300 text-cyan-800'
      attributes.onClick = () => {
        setIsDeletion(true)
        setToolbarOpen(true)
      }
    }

    return (
      <span {...attributes} className={classNames}>
        {children}
      </span>
    )
  }, [])

  useEffect(() => {
    if (highlights.length >= highlightsCount.current) {
      highlightsCount.current = highlights.length

      return
    }

    Transforms.unsetNodes(editor, ['favor', 'issue', 'info'], {
      at: [],
      match: (node) =>
        !highlights.map((highlight) => highlight.id).includes(node.highlightId),
    })
    ReactEditor.focus(editor)
    Transforms.select(editor, [0])
    ReactEditor.deselect(editor)
    highlightsCount.current = highlights.length
  }, [highlights, editor])

  useEffect(() => {
    if (!shouldStartEditing) {
      return
    }

    ReactEditor.focus(editor)
    Transforms.select(editor, [0]) // hacky solution because of slate bug

    onStartedEditing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStartEditing])

  const handlePaste = (event) => {
    const text = event.clipboardData.getData('text/plain')

    // Check if the pasted text is a URL
    if (isURL(text)) {
      const { selection } = editor
      const url = text

      if (selection && hasSelection()) {
        event.preventDefault()
        const selectedText = Editor.string(editor, selection)

        Editor.addMark(editor, 'link', {
          type: 'link',
          url,
          children: [{ text: selectedText, url }],
        })
      }

      Transforms.collapse(editor, { edge: 'end' })
    }
  }

  const hasSelection = () => {
    const selection = window.getSelection()

    return selection && selection.toString().trim() !== ''
  }

  return (
    <Slate editor={editor} value={_initialValue} onChange={onChange}>
      <HoveringToolbar
        open={toolbarOpen}
        deletion={isDeletion}
        setDeletion={setIsDeletion}
        setOpen={setToolbarOpen}
        onCreateHighlight={onCreateHighlight}
        onDeleteHighlight={onDeleteHighlight}
      />
      <div className="relative">
        <Editable
          placeholder={
            <span className="text-[10px] sm:text-sm md:text-base">
              {t('customerModal.interaction.placeholder')}
            </span>
          }
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onBlur={onBlur}
          className="px-2 py-1 ml-4 mr-2 border-2 border-transparent rounded-md pr-[88px] !border-gray-50 caret-indigo-800 focus:border-indigo-500"
          onKeyDown={(event) => {
            if (event.key === ' ' || event.key === 'Enter') {
              Editor.removeMark(editor, 'issue')
              Editor.removeMark(editor, 'favor')
              Editor.removeMark(editor, 'info')
              Editor.removeMark(editor, 'link')
            }
          }}
          onPaste={active && handlePaste}
        />
        {children}
      </div>
    </Slate>
  )
}

InteractionTextfield.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateHighlight: PropTypes.func.isRequired,
  onDeleteHighlight: PropTypes.func,
  highlights: PropTypes.array.isRequired,
  initialValue: PropTypes.string.isRequired,
  onStartedEditing: PropTypes.func,
  shouldStartEditing: PropTypes.bool,
  children: PropTypes.node,
}
