import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslation from '../../hooks/useTranslation'
import useDate from '../../hooks/useDate'
import UserListBox from '../UserListBox'

export default function HelpingTodayButton({
  tab,
  user,
  organization,
  currentFollowUpUser,
  followUpDate,
  onTabChanged,
  selectUser,
}) {
  const t = useTranslation()
  const { dateString } = useDate()
  const isToday = dateString(followUpDate) === dateString(new Date())
  const currentUserInHeader =
    user?.id === currentFollowUpUser?.id || !currentFollowUpUser

  return (
    <span
      className="flex items-center text-lg font-semibold text-center cursor-pointer group"
      onClick={() => onTabChanged('day')}>
      <span
        className={cx('', {
          'text-gray-400 group-hover:text-gray-800': tab !== 'day',
          'text-gray-900': tab === 'day ',
        })}>
        <UserListBox
          disabled={tab !== 'day'}
          value={currentFollowUpUser}
          users={organization?.users ?? []}
          currentUser={user}
          onSelect={selectUser}
        />{' '}
        To-Do
      </span>{' '}
      <span
        className={cx('ml-1', {
          'text-gray-400 group-hover:text-gray-800': tab !== 'day',
        })}>
        {' '}
        {isToday
          ? t(
              currentUserInHeader
                ? 'followUps.helping.today'
                : 'followUps.helping.today_otherPerson',
            )
          : t(
              currentUserInHeader
                ? 'followUps.helping.otherDay'
                : 'followUps.helping.otherDay_otherPerson',
              {
                dateString: followUpDate.toLocaleDateString(),
              },
            )}
      </span>
    </span>
  )
}

HelpingTodayButton.propTypes = {
  tab: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    short_name: PropTypes.string.isRequired,
  }),
  organization: PropTypes.shape({
    users: PropTypes.array,
  }),
  currentFollowUpUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    short_name: PropTypes.string.isRequired,
  }),
  followUpDate: PropTypes.any,
  onTabChanged: PropTypes.func,
  selectUser: PropTypes.func,
}
