import React from 'react'
import PropTypes from 'prop-types'
import { PlusIcon } from '@heroicons/react/solid'
import { CalendarIcon } from '@heroicons/react/outline'
import useTranslation from '../../hooks/useTranslation'

export default function EmptyState({ tab, onClick }) {
  const t = useTranslation()

  return (
    <div className="flex flex-col items-center text-center">
      {tab === 'day' ? (
        <CalendarIcon className="w-8 h-8 text-gray-400" />
      ) : (
        <svg
          className="w-12 h-12 mx-auto text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 48 48"
          aria-hidden="true">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
      )}
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        {tab === 'day'
          ? t('emptyState.noFollowUp.text')
          : t('emptyState.noCustomers.text')}
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        {tab === 'day'
          ? t('emptyState.noFollowUp.subtitle')
          : t('emptyState.noCustomers.subtitle')}
      </p>
      <div className="mt-6">
        <button
          type="button"
          onClick={onClick}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
          {tab === 'day'
            ? t('emptyState.noFollowUp.buttonText')
            : t('emptyState.noCustomers.buttonText')}
        </button>
      </div>
    </div>
  )
}

EmptyState.propTypes = {
  tab: PropTypes.string,
  onClick: PropTypes.func,
}
